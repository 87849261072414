@charset "utf-8";
body.mcs-html {
  .no-p-x-axis {
      padding-left: 0 !important;
      padding-right: 0 !important;
  }
  .no-p-y-axis {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
  }
  .no-m-x-axis {
      margin-left: 0 !important;
      margin-right: 0 !important;
  }
  .no-m-y-axis {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
  }
  .no-p-xy-axis {
      padding: 0 !important;
  }
  .no-m-xy-axis {
      margin: 0 !important;
  }
  .pt-5 {
      padding-top: 5px !important;
  }
  .pt-10 {
      padding-top: 10px !important;
  }
  .pt-15 {
      padding-top: 15px !important;
  }
  .pt-20 {
      padding-top: 20px !important;
  }
  .pt-25 {
      padding-top: 25px !important;
  }
  .pt-30 {
      padding-top: 30px !important;
  }
  .pt-35 {
      padding-top: 35px !important;
  }
  .pt-40 {
      padding-top: 40px !important;
  }
  .pt-45 {
      padding-top: 45px !important;
  }
  .pt-50 {
      padding-top: 50px !important;
  }
  .pt-55 {
      padding-top: 55px !important;
  }
  .pt-60 {
      padding-top: 60px !important;
  }
  .pt-65 {
      padding-top: 65px !important;
  }
  .pt-70 {
      padding-top: 70px !important;
  }
  .pt-75 {
      padding-top: 75px !important;
  }
  .pt-80 {
      padding-top: 80px !important;
  }
  .pt-85 {
      padding-top: 85px !important;
  }
  .pt-90 {
      padding-top: 90px !important;
  }
  .pt-95 {
      padding-top: 95px !important;
  }
  .pt-100 {
      padding-top: 100px !important;
  }
  .pr-5 {
      padding-right: 5px !important;
  }
  .pr-10 {
      padding-right: 10px !important;
  }
  .pr-15 {
      padding-right: 15px !important;
  }
  .pr-20 {
      padding-right: 20px !important;
  }
  .pr-25 {
      padding-right: 25px !important;
  }
  .pr-30 {
      padding-right: 30px !important;
  }
  .pr-35 {
      padding-right: 35px !important;
  }
  .pr-40 {
      padding-right: 40px !important;
  }
  .pr-45 {
      padding-right: 45px !important;
  }
  .pr-50 {
      padding-right: 50px !important;
  }
  .pr-55 {
      padding-right: 55px !important;
  }
  .pr-60 {
      padding-right: 60px !important;
  }
  .pr-65 {
      padding-right: 65px !important;
  }
  .pr-70 {
      padding-right: 70px !important;
  }
  .pr-75 {
      padding-right: 75px !important;
  }
  .pr-80 {
      padding-right: 80px !important;
  }
  .pr-85 {
      padding-right: 85px !important;
  }
  .pr-90 {
      padding-right: 90px !important;
  }
  .pr-95 {
      padding-right: 95px !important;
  }
  .pr-100 {
      padding-right: 100px !important;
  }
  .pb-5 {
      padding-bottom: 5px !important;
  }
  .pb-10 {
      padding-bottom: 10px !important;
  }
  .pb-15 {
      padding-bottom: 15px !important;
  }
  .pb-20 {
      padding-bottom: 20px !important;
  }
  .pb-25 {
      padding-bottom: 25px !important;
  }
  .pb-30 {
      padding-bottom: 30px !important;
  }
  .pb-35 {
      padding-bottom: 35px !important;
  }
  .pb-40 {
      padding-bottom: 40px !important;
  }
  .pb-45 {
      padding-bottom: 45px !important;
  }
  .pb-50 {
      padding-bottom: 50px !important;
  }
  .pb-55 {
      padding-bottom: 55px !important;
  }
  .pb-60 {
      padding-bottom: 60px !important;
  }
  .pb-65 {
      padding-bottom: 65px !important;
  }
  .pb-70 {
      padding-bottom: 70px !important;
  }
  .pb-75 {
      padding-bottom: 75px !important;
  }
  .pb-80 {
      padding-bottom: 80px !important;
  }
  .pb-85 {
      padding-bottom: 85px !important;
  }
  .pb-90 {
      padding-bottom: 90px !important;
  }
  .pb-95 {
      padding-bottom: 95px !important;
  }
  .pb-100 {
      padding-bottom: 100px !important;
  }
  .pl-5 {
      padding-left: 5px !important;
  }
  .pl-10 {
      padding-left: 10px !important;
  }
  .pl-15 {
      padding-left: 15px !important;
  }
  .pl-20 {
      padding-left: 20px !important;
  }
  .pl-25 {
      padding-left: 25px !important;
  }
  .pl-30 {
      padding-left: 30px !important;
  }
  .pl-35 {
      padding-left: 35px !important;
  }
  .pl-40 {
      padding-left: 40px !important;
  }
  .pl-45 {
      padding-left: 45px !important;
  }
  .pl-50 {
      padding-left: 50px !important;
  }
  .pl-55 {
      padding-left: 55px !important;
  }
  .pl-60 {
      padding-left: 60px !important;
  }
  .pl-65 {
      padding-left: 65px !important;
  }
  .pl-70 {
      padding-left: 70px !important;
  }
  .pl-75 {
      padding-left: 75px !important;
  }
  .pl-80 {
      padding-left: 80px !important;
  }
  .pl-85 {
      padding-left: 85px !important;
  }
  .pl-90 {
      padding-left: 90px !important;
  }
  .pl-95 {
      padding-left: 95px !important;
  }
  .pl-100 {
      padding-left: 100px !important;
  }
  .mt-5 {
      margin-top: 5px !important;
  }
  .mt-10 {
      margin-top: 10px !important;
  }
  .mt-15 {
      margin-top: 15px !important;
  }
  .mt-20 {
      margin-top: 20px !important;
  }
  .mt-25 {
      margin-top: 25px !important;
  }
  .mt-30 {
      margin-top: 30px !important;
  }
  .mt-35 {
      margin-top: 35px !important;
  }
  .mt-40 {
      margin-top: 40px !important;
  }
  .mt-45 {
      margin-top: 45px !important;
  }
  .mt-50 {
      margin-top: 50px !important;
  }
  .mt-55 {
      margin-top: 55px !important;
  }
  .mt-60 {
      margin-top: 60px !important;
  }
  .mt-65 {
      margin-top: 65px !important;
  }
  .mt-70 {
      margin-top: 70px !important;
  }
  .mt-75 {
      margin-top: 75px !important;
  }
  .mt-80 {
      margin-top: 80px !important;
  }
  .mt-85 {
      margin-top: 85px !important;
  }
  .mt-90 {
      margin-top: 90px !important;
  }
  .mt-95 {
      margin-top: 95px !important;
  }
  .mt-100 {
      margin-top: 100px !important;
  }
  .mr-5 {
      margin-right: 5px !important;
  }
  .mr-10 {
      margin-right: 10px !important;
  }
  .mr-15 {
      margin-right: 15px !important;
  }
  .mr-20 {
      margin-right: 20px !important;
  }
  .mr-25 {
      margin-right: 25px !important;
  }
  .mr-30 {
      margin-right: 30px !important;
  }
  .mr-35 {
      margin-right: 35px !important;
  }
  .mr-40 {
      margin-right: 40px !important;
  }
  .mr-45 {
      margin-right: 45px !important;
  }
  .mr-50 {
      margin-right: 50px !important;
  }
  .mr-55 {
      margin-right: 55px !important;
  }
  .mr-60 {
      margin-right: 60px !important;
  }
  .mr-65 {
      margin-right: 65px !important;
  }
  .mr-70 {
      margin-right: 70px !important;
  }
  .mr-75 {
      margin-right: 75px !important;
  }
  .mr-80 {
      margin-right: 80px !important;
  }
  .mr-85 {
      margin-right: 85px !important;
  }
  .mr-90 {
      margin-right: 90px !important;
  }
  .mr-95 {
      margin-right: 95px !important;
  }
  .mr-100 {
      margin-right: 100px !important;
  }
  .mb-5 {
      margin-bottom: 5px !important;
  }
  .mb-10 {
      margin-bottom: 10px !important;
  }
  .mb-15 {
      margin-bottom: 15px !important;
  }
  .mb-20 {
      margin-bottom: 20px !important;
  }
  .mb-25 {
      margin-bottom: 25px !important;
  }
  .mb-30 {
      margin-bottom: 30px !important;
  }
  .mb-35 {
      margin-bottom: 35px !important;
  }
  .mb-40 {
      margin-bottom: 40px !important;
  }
  .mb-45 {
      margin-bottom: 45px !important;
  }
  .mb-50 {
      margin-bottom: 50px !important;
  }
  .mb-55 {
      margin-bottom: 55px !important;
  }
  .mb-60 {
      margin-bottom: 60px !important;
  }
  .mb-65 {
      margin-bottom: 65px !important;
  }
  .mb-70 {
      margin-bottom: 70px !important;
  }
  .mb-75 {
      margin-bottom: 75px !important;
  }
  .mb-80 {
      margin-bottom: 80px !important;
  }
  .mb-85 {
      margin-bottom: 85px !important;
  }
  .mb-90 {
      margin-bottom: 90px !important;
  }
  .mb-95 {
      margin-bottom: 95px !important;
  }
  .mb-100 {
      margin-bottom: 100px !important;
  }
  .ml-5 {
      margin-left: 5px !important;
  }
  .ml-10 {
      margin-left: 10px !important;
  }
  .ml-15 {
      margin-left: 15px !important;
  }
  .ml-20 {
      margin-left: 20px !important;
  }
  .ml-25 {
      margin-left: 25px !important;
  }
  .ml-30 {
      margin-left: 30px !important;
  }
  .ml-35 {
      margin-left: 35px !important;
  }
  .ml-40 {
      margin-left: 40px !important;
  }
  .ml-45 {
      margin-left: 45px !important;
  }
  .ml-50 {
      margin-left: 50px !important;
  }
  .ml-55 {
      margin-left: 55px !important;
  }
  .ml-60 {
      margin-left: 60px !important;
  }
  .ml-65 {
      margin-left: 65px !important;
  }
  .ml-70 {
      margin-left: 70px !important;
  }
  .ml-75 {
      margin-left: 75px !important;
  }
  .ml-80 {
      margin-left: 80px !important;
  }
  .ml-85 {
      margin-left: 85px !important;
  }
  .ml-90 {
      margin-left: 90px !important;
  }
  .ml-95 {
      margin-left: 95px !important;
  }
  .ml-100 {
      margin-left: 100px !important;
  }
  .w-5 {
      width: 5%;
  }
  .w-10 {
      width: 10%;
  }
  .w-15 {
      width: 15%;
  }
  .w-20 {
      width: 20%;
  }
  .w-25 {
      width: 25%;
  }
  .w-30 {
      width: 30%;
  }
  .w-35 {
      width: 35%;
  }
  .w-40 {
      width: 40%;
  }
  .w-45 {
      width: 45%;
  }
  .w-50 {
      width: 50%;
  }
  .w-55 {
      width: 55%;
  }
  .w-60 {
      width: 60%;
  }
  .w-65 {
      width: 65%;
  }
  .w-70 {
      width: 70%;
  }
  .w-75 {
      width: 75%;
  }
  .w-80 {
      width: 80%;
  }
  .w-85 {
      width: 85%;
  }
  .w-90 {
      width: 90%;
  }
  .w-95 {
      width: 95%;
  }
  .w-100 {
      width: 100%;
  }
  .w-45px {
      width: 45px !important;
  }
  .w-50px {
      width: 50px !important;
  }
  .w-55px {
      width: 55px !important;
  }
  .w-50px {
      width: 50px !important;
  }
  .w-65px {
      width: 65px !important;
  }
  .w-70px {
      width: 70px !important;
  }
  .w-75px {
      width: 75px !important;
  }
  .w-80px {
      width: 80px !important;
  }
  .w-85px {
      width: 85px !important;
  }
  .w-90px {
      width: 90px !important;
  }
  .w-95px {
      width: 95px !important;
  }
  .w-100px {
      width: 100px !important;
  }
  .w-105px {
      width: 105px !important;
  }
  .w-110px {
      width: 110px !important;
  }
  .w-115px {
      width: 115px !important;
  }
  .w-120px {
      width: 120px !important;
  }
  .w-125px {
      width: 125px !important;
  }
  .w-130px {
      width: 130px !important;
  }
  .w-135px {
      width: 135px !important;
  }
  .w-140px {
      width: 140px !important;
  }
  .w-145px {
      width: 145px !important;
  }
  .w-150px {
      width: 150px !important;
  }
  .h-5 {
      height: 5%;
  }
  .h-10 {
      height: 10%;
  }
  .h-15 {
      height: 15%;
  }
  .h-20 {
      height: 20%;
  }
  .h-25 {
      height: 25%;
  }
  .h-30 {
      height: 30%;
  }
  .h-35 {
      height: 35%;
  }
  .h-40 {
      height: 40%;
  }
  .h-45 {
      height: 45%;
  }
  .h-50 {
      height: 50%;
  }
  .h-55 {
      height: 55%;
  }
  .h-60 {
      height: 60%;
  }
  .h-65 {
      height: 65%;
  }
  .h-70 {
      height: 70%;
  }
  .h-75 {
      height: 75%;
  }
  .h-80 {
      height: 80%;
  }
  .h-85 {
      height: 85%;
  }
  .h-90 {
      height: 90%;
  }
  .h-95 {
      height: 95%;
  }
  .h-100 {
      height: 100%;
  }
  .d-none {
      display: none;
  }
  .d-block {
      display: block;
  }
  .d-inline {
      display: inline;
  }
  .d-inline-block {
      display: inline-block;
  }
  .d-flex {
      display: flex;
  }
  .pos-fixed {
      position: fixed !important;
  }
  .pos-relative {
      position: relative !important;
  }
  .pos-absolute {
      position: absolute !important;
  }
  .font-8 {
      font-size: 8px !important;
  }
  .font-9 {
      font-size: 9px !important;
  }
  .font-10 {
      font-size: 10px !important;
  }
  .font-11 {
      font-size: 11px !important;
  }
  .font-12 {
      font-size: 12px !important;
  }
  .font-13 {
      font-size: 13px !important;
  }
  .font-14 {
      font-size: 14px !important;
  }
  .font-15 {
      font-size: 15px !important;
  }
  .font-16 {
      font-size: 16px !important;
  }
  .font-17 {
      font-size: 17px !important;
  }
  .font-18 {
      font-size: 18px !important;
  }
  .font-19 {
      font-size: 19px !important;
  }
  .font-20 {
      font-size: 20px !important;
  }
  .section-text-dark-bg {
      color: #fff;
      font-size: 14px;
      margin: 0;
  }
  .section-text-light-bg {
      color: #000;
      font-size: 14px;
      margin: 0;
  }
  .section-primary-title {
      color: #1e692e;
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 10px;
      text-align: center;
  }
  .section-secondary-title {
      color: #1e692e;
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 10px;
  }
  .section-primary-description {
      color: #000;
      font-size: 20px;
      font-weight: 100;
      margin-bottom: 0;
      text-align: center;
  }
  .section-secondary-description {
      color: #000;
      font-size: 16px;
      font-weight: 100;
      margin-bottom: 0;
      text-align: center;
  }
  .text-white {
      color: #fff;
  }
  .text-green {
      color: #1e692e;
  }
  .text-error {
      color: #e67676 !important;
  }
  .card-primary-title {
      color: #333;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 0;
  }
  .vertical-divider-line {
      display: flex;
      width: 1px;
      height: 100%;
      background-color: #000;
      margin: 0 25px;
  }
  .horizontal-divider-line {
      display: flex;
      width: calc(100% - 80px);
      height: 1px;
      background-color: rgba(0, 0, 0, 0.5);
      margin: 20px 40px;
  }
  .menu-divider {
      margin: 0 10px 0 10px;
      position: relative;
  }
  .menu-divider::before {
      content: "|";
      color: #fff;
      display: flex;
      font-weight: 100;
      height: 100%;
  }
  .justify-child-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  }
  .justify-child-center p {
      text-align: center;
  }
  .section-flex-wrap {
      flex-wrap: wrap;
  }
  .flex-content-center {
      align-items: center;
      justify-content: center;
  }
  a:not(.nav-link) {
      color: #1e692e;
      font-size: 14px;
  }
  a:not(.nav-link).underline-me {
      text-decoration: underline;
  }
  a:not(.nav-link).underline-me:hover {
      text-decoration: none;
  }
  .link-btn {
      font-size: 16px;
      font-weight: 100;
  }
  .link-btn.link-btn-primary.green {
      color: #1e692e;
  }
  .link-icon-btn {
      font-size: 16px;
      font-weight: 100;
      position: relative;
      display: inline-flex;
  }
  .link-icon-btn::before {
      content: "";
      display: block;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
  }
  .link-icon-btn.link-icon-24::before {
      width: 24px;
      height: 24px;
  }
  .link-icon-btn.link-icon-28::before {
      width: 28px;
      height: 28px;
  }
  .link-icon-btn.link-icon-32::before {
      width: 32px;
      height: 32px;
  }
  .link-icon-btn.icon-hive-logo::before {
      background-image: url(../../../assets/img/logo-gets.svg);
  }
  /* .link-icon-btn.icon-edit-company::before {
      background-image: url(../../../assets/img/logo-gets.svg);
  } */
  .btn-icon.icon-settings {
      background-image: url(../../../assets/img/microsite/app-icons/icon-app-settings-yellow.svg);
  }
  /* .hv-theme-blue .link-icon-btn.icon-edit-company::before,
  .hv-theme-bright .link-icon-btn.icon-edit-company::before,
  .hv-theme-light .link-icon-btn.icon-edit-company::before {
      background-image: url(../../../assets/img/logo-gets.svg);
  } */
  .hv-theme-blue .btn-icon.icon-settings,
  .hv-theme-bright .btn-icon.icon-settings,
  .hv-theme-light .btn-icon.icon-settings {
      background-image: url(../../../assets/img/microsite/app-icons/icon-app-settings-black.svg);
  }
  .icon {
      display: block;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
  }
  .icon.icon-42 {
      width: 42px;
      height: 42px;
  }
  /* .icon.icon-stars {
      background-image: url(../../../assets/img/logo-gets.svg);
  } */
  .label-icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
  }
  .label-icon::before {
      content: "";
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 100%;
      height: 100%;
      display: inline-block;
  }
  .label-icon.ic-24 {
      width: 24px;
      height: 24px;
  }
  .icon-edit-profile::before {
      background-image: url(../../../assets/img/microsite/app-icons/icon-edit-app-black.svg);
  }
  .icon-settings::before {
      background-image: url(../../../assets/img/microsite/app-icons/icon-app-settings-black.svg);
  }
  .hv-btn {
      background-color: transparent;
      font-size: 16px;
      font-weight: 500;
      height: 36px;
  }
  .hv-btn.hv-btn-primary {
      border-width: 0 !important;
      background-color: #1e692e !important;
      color: #fff;
  }
  .hv-btn.hv-btn-primary:not(:disabled):hover {
      background-color: #185525 !important;
  }
  .hv-btn.hv-btn-primary:disabled {
      cursor: not-allowed;
  }
  .hv-btn.hv-btn-primary:active,
  .hv-btn.hv-btn-primary:focus {
      background-color: #185525 !important;
      border-width: 0 !important;
      box-shadow: 0 0 6px 0 #707070 !important;
      outline: 0 !important;
  }
  .hv-btn.hv-btn-outline-primary {
      background-color: #4dbf66;
      border-color: #4dbf66;
      color: #fff;
  }
  .hv-btn.hv-btn-outline-primary:hover {
      background-color: #3ca754;
  }
  .hv-btn.hv-btn-outline-primary:active,
  .hv-btn.hv-btn-outline-primary:focus {
      border-color: #2c7a3d !important;
      background-color: #3ca754 !important;
      box-shadow: 0 0 6px 0 rgba(16, 64, 67, 0.3) !important;
      outline: 0 !important;
  }
  .hv-btn.hv-btn-outline-secondary {
      background-color: transparent;
      border-color: #a5a5a5;
      color: #a5a5a5;
  }
  .hv-btn.hv-btn-outline-secondary:hover {
      background-color: #247d37;
  }
  .hv-btn.hv-btn-outline-secondary:active,
  .hv-btn.hv-btn-outline-secondary:focus {
      border-color: #d8d8d8 !important;
      background-color: transparent !important;
      box-shadow: 0 0 6px 0 rgba(16, 64, 67, 0.3) !important;
      outline: 0 !important;
  }
  .hv-btn.green {
      background-color: #1e692e;
      border-color: #1e692e;
  }
  .hv-btn.green:not(:disabled):hover {
      background-color: #185525;
  }
  .hv-btn.green.active,
  .hv-btn.green:active,
  .hv-btn.green:focus {
      background-color: #13411d !important;
      border: 1px #1e692e solid !important;
      box-shadow: none !important;
      outline: 0 !important;
  }
  .hv-btn.green:disabled {
      cursor: not-allowed;
  }
  .hv-btn.hv-btn-outline-green {
      background-color: transparent !important;
      border-color: #1e692e !important;
      border-width: 2px !important;
      color: #1e692e !important;
  }
  .hv-btn.hv-btn-outline-green:not(:disabled):hover {
      background-color: transparent !important;
  }
  .hv-btn.hv-btn-outline-green:active,
  .hv-btn.hv-btn-outline-green:focus {
      background-color: transparent !important;
      border-color: #1e692e !important;
      border-width: 2px !important;
      box-shadow: 0 0 6px 0 #707070 !important;
      outline: 0 !important;
  }
  .hv-btn.hv-btn-loader {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
  }
  .hv-btn.hv-btn-loader label {
      margin: 0;
      cursor: inherit;
  }
  .hv-btn.hv-btn-loader span.btn-spinner,
  .hv-btn.hv-btn-loader span.status-text {
      display: none;
  }
  .hv-btn.hv-btn-loader.loading-in-progress label {
      display: none;
  }
  .hv-btn.hv-btn-loader.loading-in-progress span.btn-spinner {
      display: block;
  }
  .hv-btn.show-form-status .btn-spinner,
  .hv-btn.show-form-status .btn-text {
      display: none !important;
  }
  .hv-btn.show-form-status span.status-text {
      display: inline-flex !important;
      flex-direction: row;
  }
  .hv-btn.show-form-status .icon {
      width: 24px;
      height: 24px;
      display: block;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin-right: 5px;
  }
  .hv-btn.show-form-status .icon.icon-tick {
      background-image: url(../../../assets/img/logo-gets.svg);
  }
  .hv-btn.hv-btn-capsule-outline-secondary {
      border-color: #333;
      border-radius: 18px;
      color: #333;
      transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  .hv-btn.hv-btn-capsule-outline-secondary:hover {
      background-color: #e5e5e5 !important;
  }
  .hv-btn.hv-btn-capsule-outline-secondary:active,
  .hv-btn.hv-btn-capsule-outline-secondary:focus {
      background-color: #fff !important;
      box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2) !important;
      color: #333 !important;
      outline: 0 !important;
  }
  .btn.dropdown-toggle {
      border-color: #707070 !important;
      background-color: #fff !important;
      border-radius: 3px;
      color: #000;
  }
  .btn.dropdown-toggle:active,
  .btn.dropdown-toggle:focus {
      border-color: #707070 !important;
      box-shadow: 0 0 6px 0 #707070;
      outline: 0 !important;
  }
  .form-control-select.show .dropdown-toggle {
      border-color: #707070;
      box-shadow: 0 0 6px 0 #707070 !important;
      outline: 0 !important;
  }
  .form-control-select.default-val + button {
      color: #adadad !important;
  }
  .btn-icon {
      background-color: transparent;
      border: 0;
      outline: 0;
  }
  .btn-icon:active,
  .btn-icon:focus {
      box-shadow: none !important;
      border: 0 !important;
      outline: 0 !important;
  }
  .btn-icon-24 {
      width: 24px;
      height: 24px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
  }
  .btn-icon-28 {
      width: 28px;
      height: 28px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
  }
  .btn-icon-32 {
      width: 32px;
      height: 32px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
  }
  .btn-icon-36 {
      width: 36px;
      height: 36px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
  }
  .btn-icon.icon-bell-alerts,
  .btn-icon.icon-bell-no-alerts {
      transition: background-image 0.3s linear;
  }
  .btn-icon.icon-bell-alerts {
      background-image: url(../../../assets/img/logo-gets.svg);
  }
  .btn-icon.icon-bell-no-alerts {
      background-image: url(../../../assets/img/logo-gets.svg);
  }
  .icon-button-transparent {
      padding: 0;
      margin: 0;
      line-height: 0;
  }
  .btn.icon-button-transparent {
      display: inline-flex;
      align-items: center;
      padding: 0 5px;
      background-color: transparent !important;
      border-color: #e3bc17 !important;
      border-radius: 5px;
  }
  .btn.icon-button-transparent:active,
  .btn.icon-button-transparent:focus {
      border-color: #e3bc17 !important;
      box-shadow: 0 0 1px 1px rgba(227, 188, 23, 0.3) !important;
  }
  .btn.icon-button-transparent::after {
      color: #fff;
  }
  .hv-theme-blue .btn-icon.icon-bell-alerts,
  .hv-theme-bright .btn-icon.icon-bell-alerts,
  .hv-theme-light .btn-icon.icon-bell-alerts {
      background-image: url(../../../assets/img/logo-gets.svg);
  }
  .hv-theme-blue .btn-icon.icon-bell-no-alerts,
  .hv-theme-bright .btn-icon.icon-bell-no-alerts,
  .hv-theme-light .btn-icon.icon-bell-no-alerts {
      background-image: url(../../../assets/img/logo-gets.svg);
  }
  .hv-theme-blue .btn.icon-button-transparent,
  .hv-theme-bright .btn.icon-button-transparent,
  .hv-theme-light .btn.icon-button-transparent {
      border-color: #000 !important;
  }
  .hv-theme-blue .btn.icon-button-transparent:active,
  .hv-theme-blue .btn.icon-button-transparent:focus,
  .hv-theme-bright .btn.icon-button-transparent:active,
  .hv-theme-bright .btn.icon-button-transparent:focus,
  .hv-theme-light .btn.icon-button-transparent:active,
  .hv-theme-light .btn.icon-button-transparent:focus {
      border-color: #000 !important;
      box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.3) !important;
  }
  .hv-theme-blue .btn.icon-button-transparent::after,
  .hv-theme-bright .btn.icon-button-transparent::after,
  .hv-theme-light .btn.icon-button-transparent::after {
      color: #000;
  }
  .theme-select-dd .btn.dropdown-toggle {
      padding: 4px 8px;
  }
  .hv-form-label {
      color: #333;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 0.3rem;
  }
  .hv-form-label.label-mandatory::after {
      content: ":*";
      margin-left: 3px;
      color: #e67676;
      font-weight: 400;
  }
  .hv-form-label.label-non-mandatory::after {
      content: ":";
      margin-left: 3px;
      color: #333;
      font-weight: 400;
  }
  .note-label-mandatory {
      margin: 0 3px;
      color: #e67676;
      font-weight: 700;
      font-size: 14px;
  }
  .hv-form-control {
      border-color: #707070;
      background-color: #fff;
      border-radius: 3px;
      color: #000;
  }
  .hv-form-control:focus {
      border-color: #707070;
      box-shadow: 0 0 6px 0 #707070;
  }
  .hv-form-control.cust-error,
  .hv-form-control.error {
      border-color: #e67676;
      box-shadow: 0 0 6px 0 #e67676;
  }
  .hv-form-control + .error {
      display: none !important;
  }
  .hv-textarea {
      min-height: 100px;
      max-height: 200px;
      resize: vertical;
  }
  .form-check-label {
      color: rgba(0, 0, 0, 0.4);
      transition: color 0.3s ease-in;
  }
  .form-check-label::before,
  .form-check-label:after {
      content: "";
      display: flex;
      cursor: pointer;
      position: absolute;
      left: 0;
      top: 3px;
  }
  .form-check-label::before {
      background-color: transparent;
      border: 0;
      margin: 2px;
      width: calc(16px - 4px);
      height: calc(16px - 4px);
  }
  .form-check-label::after {
      border: 1px #707070 solid;
      border-radius: 2px;
      width: 16px;
      height: 16px;
  }
  .form-check-label.radio {
      margin-left: 20px;
      cursor: pointer;
  }
  .form-check-label.radio:hover::after {
      box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.3);
  }
  .form-check-label.radio::before {
      margin: 5px;
      width: calc(20px - 6px);
      height: calc(20px - 6px);
      transition: transform 0.15s ease-in-out;
      transform: scale(0);
  }
  .form-check-label.radio::after {
      border-width: 2px;
      width: 24px;
      height: 24px;
      transition: box-shadow 0.3s ease-in-out;
  }
  .form-check-label.radio::after,
  .form-check-label.radio::before {
      border-radius: 50%;
  }
  .hv-form-check-input {
      width: 12px;
      height: 12px;
      border: 1px #1e692e solid;
  }
  .hv-form-check-input.error {
      border-color: #e67676 !important;
      box-shadow: 0 0 6px 0 #e67676;
  }
  .hv-form-check-input.cust-error ~ .form-check-label::after,
  .hv-form-check-input.error ~ .form-check-label::after {
      border-color: #e67676 !important;
      box-shadow: 0 0 6px 0 #e67676;
  }
  .hv-form-check-input:focus {
      outline: 0;
      box-shadow: none;
  }
  .hv-form-check-input:focus ~ .form-check-label::after {
      border-color: #1e692e !important;
  }
  .hv-form-check-input:focus ~ .form-check-label.radio::after {
      border-color: #f46c10 !important;
  }
  .hv-form-check-input:checked ~ .form-check-label {
      color: #000;
  }
  .hv-form-check-input:checked ~ .form-check-label::before {
      background-color: #1e692e;
      outline: 0;
      box-shadow: none;
  }
  .hv-form-check-input:checked ~ .form-check-label:after {
      border-color: #1e692e;
  }
  .hv-form-check-input:checked ~ .form-check-label.radio::before {
      background-color: #f46c10;
      transform: scale(1);
  }
  .hv-form-check-input:checked ~ .form-check-label.radio::after {
      border-color: #f46c10;
      box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.3);
  }
  input.select-display-only {
      background-color: #fff !important;
      color: #000;
      text-align: left;
      cursor: default;
      position: absolute !important;
      left: 6px !important;
      top: 0 !important;
      z-index: 0 !important;
      border: none !important;
      border-right: 1px solid #707070 !important;
      border-radius: 3px !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      width: calc(100% - 44px);
      height: calc(100% - 2px);
      margin: 1px 0;
  }
  input.select-display-only:focus {
      box-shadow: none !important;
      outline: 0 !important;
  }
  input.select-display-only.error {
      border-right-color: #e67676 !important;
  }
  input.select-display-only.error + .bootstrap-select > button.dropdown-toggle {
      border-color: #e67676 !important;
      box-shadow: 0 0 6px 0 #e67676;
  }
  .hv-form-group {
      display: flex;
      flex-wrap: wrap;
      padding: 10px;
  }
  .hv-form-group .hv-form-group__item {
      display: inline-flex;
      margin: 10px;
      position: relative;
      align-items: center;
      flex: 1;
  }
  .hv-form-group .hv-form-group__item .form-check-label {
      margin-left: 30px;
      padding-top: 3px;
  }

  .inline-overlay-wrapper.white {
      background-color: rgba(255, 255, 255, 0);
  }
  .inline-overlay-wrapper .message-wrapper {
      margin: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  }
  .inline-overlay-wrapper .message-wrapper label {
      font-size: 24px;
      font-weight: 500;
      line-height: 1.3;
      margin: 0;
      text-align: center;
  }
  .inline-overlay-wrapper .message-wrapper label.success {
      color: #007b41;
  }
  .inline-overlay-wrapper .message-wrapper label.error {
      color: #e50000;
  }
  .inline-overlay-wrapper .message-wrapper .icon {
      width: 48px;
      height: 48px;
      display: flex;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
  }
  .inline-overlay-wrapper .message-wrapper .icon.icon-success {
      background-image: url(../../../assets/img/microsite/app-icons/icon-success-tick_animated.svg);
  }
  .form-info-wrapper {
      flex-direction: row;
      align-items: flex-start;
  }
  .form-info-wrapper .message-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      min-height: 40px;
      margin: 0 0 15px 0;
      box-sizing: border-box;
      padding: 8px;
      border-radius: 5px;
  }
  .form-info-wrapper .message-wrapper label {
      font-size: 16px;
      font-weight: 500;
      margin: 0;
      display: flex;
      flex: 1;
  }
  .form-info-wrapper .message-wrapper .icon {
      width: 32px;
      height: 32px;
      display: flex;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin-right: 5px;
  }
  .form-info-wrapper .message-wrapper.error {
      background-color: #fde8e8;
      border: 1px #f9d5d5 solid;
  }
  .form-info-wrapper .message-wrapper.error .icon {
      background-image: url(../../../assets/img/microsite/app-icons/icon-error-cross-red.svg);
  }
  .form-info-wrapper .message-wrapper.error label {
      color: #e50000;
  }
  .scale-up-center {
      -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  @-webkit-keyframes scale-up-center {
      0% {
          -webkit-transform: scale(0.5);
          transform: scale(0.5);
      }
      100% {
          -webkit-transform: scale(1);
          transform: scale(1);
      }
  }
  @keyframes scale-up-center {
      0% {
          -webkit-transform: scale(0.5);
          transform: scale(0.5);
      }
      100% {
          -webkit-transform: scale(1);
          transform: scale(1);
      }
  }
  .scale-down-center {
      -webkit-animation: scale-down-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation: scale-down-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  @-webkit-keyframes scale-down-center {
      0% {
          -webkit-transform: scale(1);
          transform: scale(1);
      }
      100% {
          -webkit-transform: scale(0.5);
          transform: scale(0.5);
      }
  }
  @keyframes scale-down-center {
      0% {
          -webkit-transform: scale(1);
          transform: scale(1);
      }
      100% {
          -webkit-transform: scale(0.5);
          transform: scale(0.5);
      }
  }
  ::-webkit-input-placeholder {
      color: #adadad !important;
      transition: opacity 250ms ease-in-out;
  }
  :focus::-webkit-input-placeholder {
      opacity: 0.5;
  }
  :-ms-input-placeholder {
      color: #adadad !important;
      transition: opacity 250ms ease-in-out;
  }
  :focus:-ms-input-placeholder {
      opacity: 0.5;
  }
  ::-moz-placeholder {
      color: #adadad !important;
      opacity: 1;
      transition: opacity 250ms ease-in-out;
  }
  :focus::-moz-placeholder {
      opacity: 0.5;
  }
  :-moz-placeholder {
      color: #adadad !important;
      opacity: 1;
      transition: opacity 250ms ease-in-out;
  }
  :focus:-moz-placeholder {
      opacity: 0.5;
  }
  .spinner-ellipsis {
      display: inline-block;
      position: relative;
      width: 64px;
      height: 30px;
  }
  .spinner-ellipsis div {
      position: absolute;
      top: 12px;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background: #1e692e;
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .spinner-ellipsis div:nth-child(1) {
      left: 6px;
      animation: spinner-ellipsis1 0.6s infinite;
  }
  .spinner-ellipsis div:nth-child(2) {
      left: 6px;
      animation: spinner-ellipsis2 0.6s infinite;
  }
  .spinner-ellipsis div:nth-child(3) {
      left: 26px;
      animation: spinner-ellipsis2 0.6s infinite;
  }
  .spinner-ellipsis div:nth-child(4) {
      left: 45px;
      animation: spinner-ellipsis3 0.6s infinite;
  }
  @keyframes spinner-ellipsis1 {
      0% {
          transform: scale(0);
      }
      100% {
          transform: scale(1);
      }
  }
  @keyframes spinner-ellipsis3 {
      0% {
          transform: scale(1);
      }
      100% {
          transform: scale(0);
      }
  }
  @keyframes spinner-ellipsis2 {
      0% {
          transform: translate(0, 0);
      }
      100% {
          transform: translate(19px, 0);
      }
  }
  .hvp-overlay-bg {
      width: 100%;
      height: 100%;
      background-color: #444;
      opacity: 0.2;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1000;
      transition: all 0.3s ease-in;
  }
  .hvp-spinner-wrapper {
      min-width: 150px;
      width: 150px;
      max-width: 180px;
      min-height: 50px;
      height: 50px;
      background-color: #fff;
      position: fixed;
      left: calc(50% - 75px);
      top: calc(50% - 31px);
      z-index: 1200;
      color: #000;
      border-radius: 5px;
      border: 1px #b7b4b4 solid;
      display: table;
      padding: 6px;
  }
  .hvp-data-loader-text-wrapper {
      display: table-cell;
      vertical-align: top;
  }
  .hvp-data-loader-text-wrapper label.hvp-data-loader-text-primary {
      margin: 0 0 0 10px;
      font-size: 0.9rem;
      font-weight: 600;
  }
  .hvp-data-loader-text-wrapper label.hvp-data-loader-text-secondary {
      margin: 0 0 0 10px;
      font-weight: 400;
      color: #898a8a;
      font-size: 0.8rem;
  }
  .hvp-spinner-body {
      display: inline-block;
      vertical-align: middle;
      margin-top: 5px;
      width: 40px;
      height: 40px;
      position: relative;
  }
  .double-bounce1,
  .double-bounce2 {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #ffd446;
      opacity: 0.6;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-animation: hvp-bounce 2s infinite ease-in-out;
      animation: hvp-bounce 2s infinite ease-in-out;
  }
  .double-bounce2 {
      -webkit-animation-delay: -1s;
      animation-delay: -1s;
  }
  @-webkit-keyframes hvp-bounce {
      0%,
      100% {
          -webkit-transform: scale(0);
      }
      50% {
          -webkit-transform: scale(1);
      }
  }
  @keyframes hvp-bounce {
      0%,
      100% {
          transform: scale(0);
          -webkit-transform: scale(0);
      }
      50% {
          transform: scale(1);
          -webkit-transform: scale(1);
      }
  }
  .moreellipses {
      font-weight: 600;
  }
  .morecontent span {
      display: none;
  }
  .morelink {
      color: #da4005 !important;
      display: block;
      font-size: inherit;
      font-weight: 600;
      text-decoration: underline;
  }
  .morelink:hover {
      text-decoration: none;
  }
  .hv-scrollbar::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      background-color: #f5f5f5;
  }
  .hv-scrollbar::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: #f5f5f5;
  }
  .hv-scrollbar::-webkit-scrollbar-thumb {
      border-radius: 8px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      background-color: #b2b2b2;
  }
  #page-action-mask {
      background-color: #000;
      opacity: 0.2;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
      width: 100%;
      height: 100%;
      display: none;
  }
}

@import './_toaster.css';
@import './media.css';
