@import './bootstrap.min.css';

body.mcs-html {
  .layout-container {
      padding: 0px 15px;
  }
  .home-section {

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
  }

  .corporate-presence li:not(:last-of-type)::after {
      content: "";
      background-color: #fff;
      width: 1px;
      height: 70%;
      position: absolute;
      right: 0;
  }

  .section-primary-title {
  color: #1e692e!important;


      font-size: 32px;
      font-weight: 600;
      margin-bottom: 10px;
      text-align: center;
  }

  .hv-section:nth-of-type(odd) {
      background-color: #fff!important;
  }
  .hv-section {
      padding: 40px 40px;
  }

  .nav-item {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      color: black;
  }

  @charset "utf-8";
  .no-p-x-axis {
      padding-left: 0 !important;
      padding-right: 0 !important;
  }
  .no-p-y-axis {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
  }
  .no-m-x-axis {
      margin-left: 0 !important;
      margin-right: 0 !important;
  }
  .no-m-y-axis {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
  }
  .no-p-xy-axis {
      padding: 0 !important;
  }
  .no-m-xy-axis {
      margin: 0 !important;
  }
  .pt-5 {
      padding-top: 5px !important;
  }
  .pt-10 {
      padding-top: 10px !important;
  }
  .pt-15 {
      padding-top: 15px !important;
  }
  .pt-20 {
      padding-top: 20px !important;
  }
  .pt-25 {
      padding-top: 25px !important;
  }
  .pt-30 {
      padding-top: 30px !important;
  }
  .pt-35 {
      padding-top: 35px !important;
  }
  .pt-40 {
      padding-top: 40px !important;
  }
  .pt-45 {
      padding-top: 45px !important;
  }
  .pt-50 {
      padding-top: 50px !important;
  }
  .pt-55 {
      padding-top: 55px !important;
  }
  .pt-60 {
      padding-top: 60px !important;
  }
  .pt-65 {
      padding-top: 65px !important;
  }
  .pt-70 {
      padding-top: 70px !important;
  }
  .pt-75 {
      padding-top: 75px !important;
  }
  .pt-80 {
      padding-top: 80px !important;
  }
  .pt-85 {
      padding-top: 85px !important;
  }
  .pt-90 {
      padding-top: 90px !important;
  }
  .pt-95 {
      padding-top: 95px !important;
  }
  .pt-100 {
      padding-top: 100px !important;
  }
  .pr-5 {
      padding-right: 5px !important;
  }
  .pr-10 {
      padding-right: 10px !important;
  }
  .pr-15 {
      padding-right: 15px !important;
  }
  .pr-20 {
      padding-right: 20px !important;
  }
  .pr-25 {
      padding-right: 25px !important;
  }
  .pr-30 {
      padding-right: 30px !important;
  }
  .pr-35 {
      padding-right: 35px !important;
  }
  .pr-40 {
      padding-right: 40px !important;
  }
  .pr-45 {
      padding-right: 45px !important;
  }
  .pr-50 {
      padding-right: 50px !important;
  }
  .pr-55 {
      padding-right: 55px !important;
  }
  .pr-60 {
      padding-right: 60px !important;
  }
  .pr-65 {
      padding-right: 65px !important;
  }
  .pr-70 {
      padding-right: 70px !important;
  }
  .pr-75 {
      padding-right: 75px !important;
  }
  .pr-80 {
      padding-right: 80px !important;
  }
  .pr-85 {
      padding-right: 85px !important;
  }
  .pr-90 {
      padding-right: 90px !important;
  }
  .pr-95 {
      padding-right: 95px !important;
  }
  .pr-100 {
      padding-right: 100px !important;
  }
  .pb-5 {
      padding-bottom: 5px !important;
  }
  .pb-10 {
      padding-bottom: 10px !important;
  }
  .pb-15 {
      padding-bottom: 15px !important;
  }
  .pb-20 {
      padding-bottom: 20px !important;
  }
  .pb-25 {
      padding-bottom: 25px !important;
  }
  .pb-30 {
      padding-bottom: 30px !important;
  }
  .pb-35 {
      padding-bottom: 35px !important;
  }
  .pb-40 {
      padding-bottom: 40px !important;
  }
  .pb-45 {
      padding-bottom: 45px !important;
  }
  .pb-50 {
      padding-bottom: 50px !important;
  }
  .pb-55 {
      padding-bottom: 55px !important;
  }
  .pb-60 {
      padding-bottom: 60px !important;
  }
  .pb-65 {
      padding-bottom: 65px !important;
  }
  .pb-70 {
      padding-bottom: 70px !important;
  }
  .pb-75 {
      padding-bottom: 75px !important;
  }
  .pb-80 {
      padding-bottom: 80px !important;
  }
  .pb-85 {
      padding-bottom: 85px !important;
  }
  .pb-90 {
      padding-bottom: 90px !important;
  }
  .pb-95 {
      padding-bottom: 95px !important;
  }
  .pb-100 {
      padding-bottom: 100px !important;
  }
  .pl-5 {
      padding-left: 5px !important;
  }
  .pl-10 {
      padding-left: 10px !important;
  }
  .pl-15 {
      padding-left: 15px !important;
  }
  .pl-20 {
      padding-left: 20px !important;
  }
  .pl-25 {
      padding-left: 25px !important;
  }
  .pl-30 {
      padding-left: 30px !important;
  }
  .pl-35 {
      padding-left: 35px !important;
  }
  .pl-40 {
      padding-left: 40px !important;
  }
  .pl-45 {
      padding-left: 45px !important;
  }
  .pl-50 {
      padding-left: 50px !important;
  }
  .pl-55 {
      padding-left: 55px !important;
  }
  .pl-60 {
      padding-left: 60px !important;
  }
  .pl-65 {
      padding-left: 65px !important;
  }
  .pl-70 {
      padding-left: 70px !important;
  }
  .pl-75 {
      padding-left: 75px !important;
  }
  .pl-80 {
      padding-left: 80px !important;
  }
  .pl-85 {
      padding-left: 85px !important;
  }
  .pl-90 {
      padding-left: 90px !important;
  }
  .pl-95 {
      padding-left: 95px !important;
  }
  .pl-100 {
      padding-left: 100px !important;
  }
  .mt-5 {
      margin-top: 5px !important;
  }
  .mt-10 {
      margin-top: 10px !important;
  }
  .mt-15 {
      margin-top: 15px !important;
  }
  .mt-20 {
      margin-top: 20px !important;
  }
  .mt-25 {
      margin-top: 25px !important;
  }
  .mt-30 {
      margin-top: 30px !important;
  }
  .mt-35 {
      margin-top: 35px !important;
  }
  .mt-40 {
      margin-top: 40px !important;
  }
  .mt-45 {
      margin-top: 45px !important;
  }
  .mt-50 {
      margin-top: 50px !important;
  }
  .mt-55 {
      margin-top: 55px !important;
  }
  .mt-60 {
      margin-top: 60px !important;
  }
  .mt-65 {
      margin-top: 65px !important;
  }
  .mt-70 {
      margin-top: 70px !important;
  }
  .mt-75 {
      margin-top: 75px !important;
  }
  .mt-80 {
      margin-top: 80px !important;
  }
  .mt-85 {
      margin-top: 85px !important;
  }
  .mt-90 {
      margin-top: 90px !important;
  }
  .mt-95 {
      margin-top: 95px !important;
  }
  .mt-100 {
      margin-top: 100px !important;
  }
  .mr-5 {
      margin-right: 5px !important;
  }
  .mr-10 {
      margin-right: 10px !important;
  }
  .mr-15 {
      margin-right: 15px !important;
  }
  .mr-20 {
      margin-right: 20px !important;
  }
  .mr-25 {
      margin-right: 25px !important;
  }
  .mr-30 {
      margin-right: 30px !important;
  }
  .mr-35 {
      margin-right: 35px !important;
  }
  .mr-40 {
      margin-right: 40px !important;
  }
  .mr-45 {
      margin-right: 45px !important;
  }
  .mr-50 {
      margin-right: 50px !important;
  }
  .mr-55 {
      margin-right: 55px !important;
  }
  .mr-60 {
      margin-right: 60px !important;
  }
  .mr-65 {
      margin-right: 65px !important;
  }
  .mr-70 {
      margin-right: 70px !important;
  }
  .mr-75 {
      margin-right: 75px !important;
  }
  .mr-80 {
      margin-right: 80px !important;
  }
  .mr-85 {
      margin-right: 85px !important;
  }
  .mr-90 {
      margin-right: 90px !important;
  }
  .mr-95 {
      margin-right: 95px !important;
  }
  .mr-100 {
      margin-right: 100px !important;
  }
  .mb-5 {
      margin-bottom: 5px !important;
  }
  .mb-10 {
      margin-bottom: 10px !important;
  }
  .mb-15 {
      margin-bottom: 15px !important;
  }
  .mb-20 {
      margin-bottom: 20px !important;
  }
  .mb-25 {
      margin-bottom: 25px !important;
  }
  .mb-30 {
      margin-bottom: 30px !important;
  }
  .mb-35 {
      margin-bottom: 35px !important;
  }
  .mb-40 {
      margin-bottom: 40px !important;
  }
  .mb-45 {
      margin-bottom: 45px !important;
  }
  .mb-50 {
      margin-bottom: 50px !important;
  }
  .mb-55 {
      margin-bottom: 55px !important;
  }
  .mb-60 {
      margin-bottom: 60px !important;
  }
  .mb-65 {
      margin-bottom: 65px !important;
  }
  .mb-70 {
      margin-bottom: 70px !important;
  }
  .mb-75 {
      margin-bottom: 75px !important;
  }
  .mb-80 {
      margin-bottom: 80px !important;
  }
  .mb-85 {
      margin-bottom: 85px !important;
  }
  .mb-90 {
      margin-bottom: 90px !important;
  }
  .mb-95 {
      margin-bottom: 95px !important;
  }
  .mb-100 {
      margin-bottom: 100px !important;
  }
  .ml-5 {
      margin-left: 5px !important;
  }
  .ml-10 {
      margin-left: 10px !important;
  }
  .ml-15 {
      margin-left: 15px !important;
  }
  .ml-20 {
      margin-left: 20px !important;
  }
  .ml-25 {
      margin-left: 25px !important;
  }
  .ml-30 {
      margin-left: 30px !important;
  }
  .ml-35 {
      margin-left: 35px !important;
  }
  .ml-40 {
      margin-left: 40px !important;
  }
  .ml-45 {
      margin-left: 45px !important;
  }
  .ml-50 {
      margin-left: 50px !important;
  }
  .ml-55 {
      margin-left: 55px !important;
  }
  .ml-60 {
      margin-left: 60px !important;
  }
  .ml-65 {
      margin-left: 65px !important;
  }
  .ml-70 {
      margin-left: 70px !important;
  }
  .ml-75 {
      margin-left: 75px !important;
  }
  .ml-80 {
      margin-left: 80px !important;
  }
  .ml-85 {
      margin-left: 85px !important;
  }
  .ml-90 {
      margin-left: 90px !important;
  }
  .ml-95 {
      margin-left: 95px !important;
  }
  .ml-100 {
      margin-left: 100px !important;
  }
  .w-5 {
      width: 5%;
  }
  .w-10 {
      width: 10%;
  }
  .w-15 {
      width: 15%;
  }
  .w-20 {
      width: 20%;
  }
  .w-25 {
      width: 25%;
  }
  .w-30 {
      width: 30%;
  }
  .w-35 {
      width: 35%;
  }
  .w-40 {
      width: 40%;
  }
  .w-45 {
      width: 45%;
  }
  .w-50 {
      width: 50%;
  }
  .w-55 {
      width: 55%;
  }
  .w-60 {
      width: 60%;
  }
  .w-65 {
      width: 65%;
  }
  .w-70 {
      width: 70%;
  }
  .w-75 {
      width: 75%;
  }
  .w-80 {
      width: 80%;
  }
  .w-85 {
      width: 85%;
  }
  .w-90 {
      width: 90%;
  }
  .w-95 {
      width: 95%;
  }
  .w-100 {
      width: 100%;
  }
  .w-45px {
      width: 45px !important;
  }
  .w-50px {
      width: 50px !important;
  }
  .w-55px {
      width: 55px !important;
  }
  .w-50px {
      width: 50px !important;
  }
  .w-65px {
      width: 65px !important;
  }
  .w-70px {
      width: 70px !important;
  }
  .w-75px {
      width: 75px !important;
  }
  .w-80px {
      width: 80px !important;
  }
  .w-85px {
      width: 85px !important;
  }
  .w-90px {
      width: 90px !important;
  }
  .w-95px {
      width: 95px !important;
  }
  .w-100px {
      width: 100px !important;
  }
  .w-105px {
      width: 105px !important;
  }
  .w-110px {
      width: 110px !important;
  }
  .w-115px {
      width: 115px !important;
  }
  .w-120px {
      width: 120px !important;
  }
  .w-125px {
      width: 125px !important;
  }
  .w-130px {
      width: 130px !important;
  }
  .w-135px {
      width: 135px !important;
  }
  .w-140px {
      width: 140px !important;
  }
  .w-145px {
      width: 145px !important;
  }
  .w-150px {
      width: 150px !important;
  }
  .h-5 {
      height: 5%;
  }
  .h-10 {
      height: 10%;
  }
  .h-15 {
      height: 15%;
  }
  .h-20 {
      height: 20%;
  }
  .h-25 {
      height: 25%;
  }
  .h-30 {
      height: 30%;
  }
  .h-35 {
      height: 35%;
  }
  .h-40 {
      height: 40%;
  }
  .h-45 {
      height: 45%;
  }
  .h-50 {
      height: 50%;
  }
  .h-55 {
      height: 55%;
  }
  .h-60 {
      height: 60%;
  }
  .h-65 {
      height: 65%;
  }
  .h-70 {
      height: 70%;
  }
  .h-75 {
      height: 75%;
  }
  .h-80 {
      height: 80%;
  }
  .h-85 {
      height: 85%;
  }
  .h-90 {
      height: 90%;
  }
  .h-95 {
      height: 95%;
  }
  .h-100 {
      height: 100%;
  }
  .d-none {
      display: none;
  }
  .d-block {
      display: block;
  }
  .d-inline {
      display: inline;
  }
  .d-inline-block {
      display: inline-block;
  }
  .d-flex {
      display: flex;
  }
  .pos-fixed {
      position: fixed !important;
  }
  .pos-relative {
      position: relative !important;
  }
  .pos-absolute {
      position: absolute !important;
  }
  .font-8 {
      font-size: 8px !important;
  }
  .font-9 {
      font-size: 9px !important;
  }
  .font-10 {
      font-size: 10px !important;
  }
  .font-11 {
      font-size: 11px !important;
  }
  .font-12 {
      font-size: 12px !important;
  }
  .font-13 {
      font-size: 13px !important;
  }
  .font-14 {
      font-size: 14px !important;
  }
  .font-15 {
      font-size: 15px !important;
  }
  .font-16 {
      font-size: 16px !important;
  }
  .font-17 {
      font-size: 17px !important;
  }
  .font-18 {
      font-size: 18px !important;
  }
  .font-19 {
      font-size: 19px !important;
  }
  .font-20 {
      font-size: 20px !important;
  }
  .section-text-dark-bg {
      color: #fff;
      font-size: 14px;
      margin: 0;
  }
  .section-text-light-bg {
      color: #000;
      font-size: 14px;
      margin: 0;
  }
  .section-primary-title {
      color: #1e692e;
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 10px;
      text-align: center;
  }
  .section-secondary-title {
      color: #1e692e;
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 10px;
  }
  .section-primary-description {
      color: #000;
      font-size: 20px;
      font-weight: 100;
      margin-bottom: 0;
      text-align: center;
  }
  .section-secondary-description {
      color: #000;
      font-size: 16px;
      font-weight: 100;
      margin-bottom: 0;
      text-align: center;
  }
  .text-white {
      color: #fff;
  }
  .text-green {
      color: #1e692e;
  }
  .text-error {
      color: #e67676 !important;
  }
  .card-primary-title {
      color: #333;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 0;
  }
  .vertical-divider-line {
      display: flex;
      width: 1px;
      height: 100%;
      background-color: #000;
      margin: 0 25px;
  }
  .horizontal-divider-line {
      display: flex;
      width: calc(100% - 80px);
      height: 1px;
      background-color: rgba(0, 0, 0, 0.5);
      margin: 20px 40px;
  }
  .menu-divider {
      margin: 0 10px 0 10px;
      position: relative;
  }
  .menu-divider::before {
      content: "|";
      color: #fff;
      display: flex;
      font-weight: 100;
      height: 100%;
  }
  .justify-child-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  }
  .justify-child-center p {
      text-align: center;
  }
  .section-flex-wrap {
      flex-wrap: wrap;
  }
  .flex-content-center {
      align-items: center;
      justify-content: center;
  }
  a:not(.nav-link) {
      color: #1e692e;
      font-size: 14px;
  }
  a:not(.nav-link).underline-me {
      text-decoration: underline;
  }
  a:not(.nav-link).underline-me:hover {
      text-decoration: none;
  }
  .link-btn {
      font-size: 16px;
      font-weight: 100;
  }
  .link-btn.link-btn-primary.green {
      color: #1e692e;
  }
  .link-icon-btn {
      font-size: 16px;
      font-weight: 100;
      position: relative;
      display: inline-flex;
  }
  .link-icon-btn::before {
      content: "";
      display: block;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
  }
  .link-icon-btn.link-icon-24::before {
      width: 24px;
      height: 24px;
  }
  .link-icon-btn.link-icon-28::before {
      width: 28px;
      height: 28px;
  }
  .link-icon-btn.link-icon-32::before {
      width: 32px;
      height: 32px;
  }
  /* .link-icon-btn.icon-hive-logo::before {
      background-image: url(../../../assets/img/microsite/app-icons/icon-hive-logo.svg);
  }
  .link-icon-btn.icon-edit-company::before {
      background-image: url(../../../assets/img/microsite/app-icons/icon-edit-app-yellow.svg);
  }*/

  .btn-icon.icon-settings {
      background-image: url(../../../assets/img/microsite/app-icons/icon-app-settings-yellow.svg);
  }
  /* .hv-theme-blue .link-icon-btn.icon-edit-company::before,
  .hv-theme-bright .link-icon-btn.icon-edit-company::before,
  .hv-theme-light .link-icon-btn.icon-edit-company::before {
      background-image: url(../../../assets/img/microsite/app-icons/icon-edit-app-black.svg);
  }*/
  .hv-theme-blue .btn-icon.icon-settings,
  .hv-theme-bright .btn-icon.icon-settings,
  .hv-theme-light .btn-icon.icon-settings {
      background-image: url(../../../assets/img/microsite/app-icons/icon-app-settings-black.svg);
  }
  .icon {
      display: block;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
  }
  .icon.icon-42 {
      width: 42px;
      height: 42px;
  }
  /* .icon.icon-stars {
      background-image: url(../../../assets/img/microsite/app-icons/icon-stars-green.svg);
  } */
  .label-icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
  }
  .label-icon::before {
      content: "";
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 100%;
      height: 100%;
      display: inline-block;
  }
  .label-icon.ic-24 {
      width: 24px;
      height: 24px;
  }
  .icon-edit-profile::before {
      background-image: url(../../../assets/img/microsite/app-icons/icon-edit-app-black.svg);
  }

  .icon-settings::before {
      background-image: url(../../../assets/img/microsite/app-icons/icon-app-settings-black.svg);
  }
  .hv-btn {
      background-color: transparent;
      font-size: 16px;
      font-weight: 500;
      height: 36px;
  }
  .hv-btn.hv-btn-primary {
      border-width: 0 !important;
      background-color: #1e692e !important;
      color: #fff;
  }
  .hv-btn.hv-btn-primary:not(:disabled):hover {
      background-color: #185525 !important;
  }
  .hv-btn.hv-btn-primary:disabled {
      cursor: not-allowed;
  }
  .hv-btn.hv-btn-primary:active,
  .hv-btn.hv-btn-primary:focus {
      background-color: #185525 !important;
      border-width: 0 !important;
      box-shadow: 0 0 6px 0 #707070 !important;
      outline: 0 !important;
  }
  .hv-btn.hv-btn-outline-primary {
      background-color: #4dbf66;
      border-color: #4dbf66;
      color: #fff;
  }
  .hv-btn.hv-btn-outline-primary:hover {
      background-color: #3ca754;
  }
  .hv-btn.hv-btn-outline-primary:active,
  .hv-btn.hv-btn-outline-primary:focus {
      border-color: #2c7a3d !important;
      background-color: #3ca754 !important;
      box-shadow: 0 0 6px 0 rgba(16, 64, 67, 0.3) !important;
      outline: 0 !important;
  }
  .hv-btn.hv-btn-outline-secondary {
      background-color: transparent;
      border-color: #a5a5a5;
      color: #a5a5a5;
  }
  .hv-btn.hv-btn-outline-secondary:hover {
      background-color: #247d37;
  }
  .hv-btn.hv-btn-outline-secondary:active,
  .hv-btn.hv-btn-outline-secondary:focus {
      border-color: #d8d8d8 !important;
      background-color: transparent !important;
      box-shadow: 0 0 6px 0 rgba(16, 64, 67, 0.3) !important;
      outline: 0 !important;
  }
  .hv-btn.green {
      background-color: #1e692e;
      border-color: #1e692e;
  }
  .hv-btn.green:not(:disabled):hover {
      background-color: #185525;
  }
  .hv-btn.green.active,
  .hv-btn.green:active,
  .hv-btn.green:focus {
      background-color: #13411d !important;
      border: 1px #1e692e solid !important;
      box-shadow: none !important;
      outline: 0 !important;
  }
  .hv-btn.green:disabled {
      cursor: not-allowed;
  }
  .hv-btn.hv-btn-outline-green {
      background-color: transparent !important;
      border-color: #1e692e !important;
      border-width: 2px !important;
      color: #1e692e !important;
  }
  .hv-btn.hv-btn-outline-green:not(:disabled):hover {
      background-color: transparent !important;
  }
  .hv-btn.hv-btn-outline-green:active,
  .hv-btn.hv-btn-outline-green:focus {
      background-color: transparent !important;
      border-color: #1e692e !important;
      border-width: 2px !important;
      box-shadow: 0 0 6px 0 #707070 !important;
      outline: 0 !important;
  }
  .hv-btn.hv-btn-loader {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
  }
  .hv-btn.hv-btn-loader label {
      margin: 0;
      cursor: inherit;
  }
  .hv-btn.hv-btn-loader span.btn-spinner,
  .hv-btn.hv-btn-loader span.status-text {
      display: none;
  }
  .hv-btn.hv-btn-loader.loading-in-progress label {
      display: none;
  }
  .hv-btn.hv-btn-loader.loading-in-progress span.btn-spinner {
      display: block;
  }
  .hv-btn.show-form-status .btn-spinner,
  .hv-btn.show-form-status .btn-text {
      display: none !important;
  }
  .hv-btn.show-form-status span.status-text {
      display: inline-flex !important;
      flex-direction: row;
  }
  .hv-btn.show-form-status .icon {
      width: 24px;
      height: 24px;
      display: block;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin-right: 5px;
  }
  /* .hv-btn.show-form-status .icon.icon-tick {
      background-image: url(../../../assets/img/microsite/app-icons/icon-tick.svg);
  } */
  .hv-btn.hv-btn-capsule-outline-secondary {
      border-color: #333;
      border-radius: 18px;
      color: #333;
      transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  .hv-btn.hv-btn-capsule-outline-secondary:hover {
      background-color: #e5e5e5 !important;
  }
  .hv-btn.hv-btn-capsule-outline-secondary:active,
  .hv-btn.hv-btn-capsule-outline-secondary:focus {
      background-color: #fff !important;
      box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2) !important;
      color: #333 !important;
      outline: 0 !important;
  }
  .btn.dropdown-toggle {
      border-color: #707070 !important;
      background-color: #fff !important;
      border-radius: 3px;
      color: #000;
  }
  .btn.dropdown-toggle:active,
  .btn.dropdown-toggle:focus {
      border-color: #707070 !important;
      box-shadow: 0 0 6px 0 #707070;
      outline: 0 !important;
  }
  .form-control-select.show .dropdown-toggle {
      border-color: #707070;
      box-shadow: 0 0 6px 0 #707070 !important;
      outline: 0 !important;
  }
  .form-control-select.default-val + button {
      color: #adadad !important;
  }
  .btn-icon {
      background-color: transparent;
      border: 0;
      outline: 0;
  }
  .btn-icon:active,
  .btn-icon:focus {
      box-shadow: none !important;
      border: 0 !important;
      outline: 0 !important;
  }
  .btn-icon-24 {
      width: 24px;
      height: 24px;
      background-size: contain;
      background-repeat: no-repeat;
  }
  .btn-icon-28 {
      width: 28px;
      height: 28px;
      background-size: contain;
      background-repeat: no-repeat;
  }
  .btn-icon-32 {
      width: 32px;
      height: 32px;
      background-size: contain;
      background-repeat: no-repeat;
  }
  .btn-icon-36 {
      width: 36px;
      height: 36px;
      background-size: contain;
      background-repeat: no-repeat;
  }
  /* .btn-icon.icon-bell-alerts {
      background-image: url(../../../assets/img/microsite/app-icons/icon-bell-with-alerts-yellow.svg);
  }
  .btn-icon.icon-bell-no-alerts {
      background-image: url(../../../assets/img/microsite/app-icons/icon-bell-with-no-alerts-yellow.svg);
  } */
  .icon-button-transparent {
      padding: 0;
      margin: 0;
      line-height: 0;
  }
  .btn.icon-button-transparent {
      display: inline-flex;
      align-items: center;
      padding: 0 5px;
      background-color: transparent !important;
      border-color: #e3bc17 !important;
      border-radius: 5px;
  }
  .btn.icon-button-transparent:active,
  .btn.icon-button-transparent:focus {
      border-color: #e3bc17 !important;
      box-shadow: 0 0 1px 1px rgba(227, 188, 23, 0.3) !important;
  }
  .btn.icon-button-transparent::after {
      color: #fff;
  }
  /* .hv-theme-blue .btn-icon.icon-bell-alerts,
  .hv-theme-bright .btn-icon.icon-bell-alerts,
  .hv-theme-light .btn-icon.icon-bell-alerts {
      background-image: url(../../../assets/img/microsite/app-icons/icon-bell-with-alerts-black.svg);
  }
  .hv-theme-blue .btn-icon.icon-bell-no-alerts,
  .hv-theme-bright .btn-icon.icon-bell-no-alerts,
  .hv-theme-light .btn-icon.icon-bell-no-alerts {
      background-image: url(../../../assets/img/microsite/app-icons/icon-bell-with-no-alerts-black.svg);
  } */
  .hv-theme-blue .btn.icon-button-transparent,
  .hv-theme-bright .btn.icon-button-transparent,
  .hv-theme-light .btn.icon-button-transparent {
      border-color: #000 !important;
  }
  .hv-theme-blue .btn.icon-button-transparent:active,
  .hv-theme-blue .btn.icon-button-transparent:focus,
  .hv-theme-bright .btn.icon-button-transparent:active,
  .hv-theme-bright .btn.icon-button-transparent:focus,
  .hv-theme-light .btn.icon-button-transparent:active,
  .hv-theme-light .btn.icon-button-transparent:focus {
      border-color: #000 !important;
      box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.3) !important;
  }
  .hv-theme-blue .btn.icon-button-transparent::after,
  .hv-theme-bright .btn.icon-button-transparent::after,
  .hv-theme-light .btn.icon-button-transparent::after {
      color: #000;
  }
  .theme-select-dd .btn.dropdown-toggle {
      padding: 4px 8px;
  }
  .hv-form-label {
      color: #333;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 0.3rem;
  }
  .hv-form-label.label-mandatory::after {
      content: ":*";
      margin-left: 3px;
      color: #e67676;
      font-weight: 400;
  }
  .hv-form-label.label-non-mandatory::after {
      content: ":";
      margin-left: 3px;
      color: #333;
      font-weight: 400;
  }
  .note-label-mandatory {
      margin: 0 3px;
      color: #e67676;
      font-weight: 700;
      font-size: 14px;
  }
  .hv-form-control {
      border-color: #707070;
      background-color: #fff;
      border-radius: 3px;
      color: #000;
  }
  .hv-form-control:focus {
      border-color: #707070;
      box-shadow: 0 0 6px 0 #707070;
  }
  .hv-form-control.cust-error,
  .hv-form-control.error {
      border-color: #e67676;
      box-shadow: 0 0 6px 0 #e67676;
  }
  .hv-form-control + .error {
      display: none !important;
  }
  .hv-textarea {
      min-height: 100px;
      max-height: 200px;
      resize: vertical;
  }
  .form-check-label {
      color: rgba(0, 0, 0, 0.4);
      transition: color 0.3s ease-in;
  }
  .form-check-label::before,
  .form-check-label:after {
      content: "";
      display: flex;
      cursor: pointer;
      position: absolute;
      left: 0;
      top: 3px;
  }
  .form-check-label::before {
      background-color: transparent;
      border: 0;
      margin: 2px;
      width: calc(16px - 4px);
      height: calc(16px - 4px);
  }
  .form-check-label::after {
      border: 1px #707070 solid;
      border-radius: 2px;
      width: 16px;
      height: 16px;
  }
  .form-check-label.radio {
      margin-left: 20px;
      cursor: pointer;
  }
  .form-check-label.radio:hover::after {
      box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.3);
  }
  .form-check-label.radio::before {
      margin: 5px;
      width: calc(20px - 6px);
      height: calc(20px - 6px);
      transition: transform 0.15s ease-in-out;
      transform: scale(0);
  }
  .form-check-label.radio::after {
      border-width: 2px;
      width: 24px;
      height: 24px;
      transition: box-shadow 0.3s ease-in-out;
  }
  .form-check-label.radio::after,
  .form-check-label.radio::before {
      border-radius: 50%;
  }
  .hv-form-check-input {
      width: 12px;
      height: 12px;
      border: 1px #1e692e solid;
  }
  .hv-form-check-input.error {
      border-color: #e67676 !important;
      box-shadow: 0 0 6px 0 #e67676;
  }
  .hv-form-check-input.cust-error ~ .form-check-label::after,
  .hv-form-check-input.error ~ .form-check-label::after {
      border-color: #e67676 !important;
      box-shadow: 0 0 6px 0 #e67676;
  }
  .hv-form-check-input:focus {
      outline: 0;
      box-shadow: none;
  }
  .hv-form-check-input:focus ~ .form-check-label::after {
      border-color: #1e692e !important;
  }
  .hv-form-check-input:focus ~ .form-check-label.radio::after {
      border-color: #f46c10 !important;
  }
  .hv-form-check-input:checked ~ .form-check-label {
      color: #000;
  }
  .hv-form-check-input:checked ~ .form-check-label::before {
      background-color: #1e692e;
      outline: 0;
      box-shadow: none;
  }
  .hv-form-check-input:checked ~ .form-check-label:after {
      border-color: #1e692e;
  }
  .hv-form-check-input:checked ~ .form-check-label.radio::before {
      background-color: #f46c10;
      transform: scale(1);
  }
  .hv-form-check-input:checked ~ .form-check-label.radio::after {
      border-color: #f46c10;
      box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.3);
  }
  input.select-display-only {
      background-color: #fff !important;
      color: #000;
      text-align: left;
      cursor: default;
      position: absolute !important;
      left: 6px !important;
      top: 0 !important;
      z-index: 0 !important;
      border: none !important;
      border-right: 1px solid #707070 !important;
      border-radius: 3px !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      width: calc(100% - 44px);
      height: calc(100% - 2px);
      margin: 1px 0;
  }
  input.select-display-only:focus {
      box-shadow: none !important;
      outline: 0 !important;
  }
  input.select-display-only.error {
      border-right-color: #e67676 !important;
  }
  input.select-display-only.error + .bootstrap-select > button.dropdown-toggle {
      border-color: #e67676 !important;
      box-shadow: 0 0 6px 0 #e67676;
  }
  .hv-form-group {
      display: flex;
      flex-wrap: wrap;
      padding: 10px;
  }
  .hv-form-group .hv-form-group__item {
      display: inline-flex;
      margin: 10px;
      position: relative;
      align-items: center;
      flex: 1;
  }
  .hv-form-group .hv-form-group__item .form-check-label {
      margin-left: 30px;
      padding-top: 3px;
  }
  .inline-overlay-wrapper {
      /* width: 100%;
      height: calc(100% - 50px);
      margin-top: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10; */
  }
  .inline-overlay-wrapper.white {
      background-color: rgba(255, 255, 255, 0);
  }
  .inline-overlay-wrapper .message-wrapper {
      margin: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  }
  .inline-overlay-wrapper .message-wrapper label {
      font-size: 24px;
      font-weight: 500;
      line-height: 1.3;
      margin: 0;
      text-align: center;
  }
  .inline-overlay-wrapper .message-wrapper label.success {
      color: #007b41;
  }
  .inline-overlay-wrapper .message-wrapper label.error {
      color: #e50000;
  }
  .inline-overlay-wrapper .message-wrapper .icon {
      width: 48px;
      height: 48px;
      display: flex;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
  }
  .inline-overlay-wrapper .message-wrapper .icon.icon-success {
      background-image: url(../../../assets/img/microsite/app-icons/icon-success-tick_animated.svg);
  }
  .form-info-wrapper {
      flex-direction: row;
      align-items: flex-start;
  }
  .form-info-wrapper .message-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      min-height: 40px;
      margin: 0 0 15px 0;
      box-sizing: border-box;
      padding: 8px;
      border-radius: 5px;
  }
  .form-info-wrapper .message-wrapper label {
      font-size: 16px;
      font-weight: 500;
      margin: 0;
      display: flex;
      flex: 1;
  }
  .form-info-wrapper .message-wrapper .icon {
      width: 32px;
      height: 32px;
      display: flex;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin-right: 5px;
  }
  .form-info-wrapper .message-wrapper.error {
      background-color: #fde8e8;
      border: 1px #f9d5d5 solid;
  }
  .form-info-wrapper .message-wrapper.error .icon {
      background-image: url(../../../assets/img/microsite/app-icons/icon-error-cross-red.svg);
  }
  .form-info-wrapper .message-wrapper.error label {
      color: #e50000;
  }
  .scale-up-center {
      -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  @-webkit-keyframes scale-up-center {
      0% {
          -webkit-transform: scale(0.5);
          transform: scale(0.5);
      }
      100% {
          -webkit-transform: scale(1);
          transform: scale(1);
      }
  }
  @keyframes scale-up-center {
      0% {
          -webkit-transform: scale(0.5);
          transform: scale(0.5);
      }
      100% {
          -webkit-transform: scale(1);
          transform: scale(1);
      }
  }
  .scale-down-center {
      -webkit-animation: scale-down-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation: scale-down-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  @-webkit-keyframes scale-down-center {
      0% {
          -webkit-transform: scale(1);
          transform: scale(1);
      }
      100% {
          -webkit-transform: scale(0.5);
          transform: scale(0.5);
      }
  }
  @keyframes scale-down-center {
      0% {
          -webkit-transform: scale(1);
          transform: scale(1);
      }
      100% {
          -webkit-transform: scale(0.5);
          transform: scale(0.5);
      }
  }
  ::-webkit-input-placeholder {
      color: #adadad !important;
      transition: opacity 250ms ease-in-out;
  }
  :focus::-webkit-input-placeholder {
      opacity: 0.5;
  }
  :-ms-input-placeholder {
      color: #adadad !important;
      transition: opacity 250ms ease-in-out;
  }
  :focus:-ms-input-placeholder {
      opacity: 0.5;
  }
  ::-moz-placeholder {
      color: #adadad !important;
      opacity: 1;
      transition: opacity 250ms ease-in-out;
  }
  :focus::-moz-placeholder {
      opacity: 0.5;
  }
  :-moz-placeholder {
      color: #adadad !important;
      opacity: 1;
      transition: opacity 250ms ease-in-out;
  }
  :focus:-moz-placeholder {
      opacity: 0.5;
  }
  .spinner-ellipsis {
      display: inline-block;
      position: relative;
      width: 64px;
      height: 30px;
  }
  .spinner-ellipsis div {
      position: absolute;
      top: 12px;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background: #1e692e;
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .spinner-ellipsis div:nth-child(1) {
      left: 6px;
      animation: spinner-ellipsis1 0.6s infinite;
  }
  .spinner-ellipsis div:nth-child(2) {
      left: 6px;
      animation: spinner-ellipsis2 0.6s infinite;
  }
  .spinner-ellipsis div:nth-child(3) {
      left: 26px;
      animation: spinner-ellipsis2 0.6s infinite;
  }
  .spinner-ellipsis div:nth-child(4) {
      left: 45px;
      animation: spinner-ellipsis3 0.6s infinite;
  }
  @keyframes spinner-ellipsis1 {
      0% {
          transform: scale(0);
      }
      100% {
          transform: scale(1);
      }
  }
  @keyframes spinner-ellipsis3 {
      0% {
          transform: scale(1);
      }
      100% {
          transform: scale(0);
      }
  }
  @keyframes spinner-ellipsis2 {
      0% {
          transform: translate(0, 0);
      }
      100% {
          transform: translate(19px, 0);
      }
  }
  .hvp-overlay-bg {
      width: 100%;
      height: 100%;
      background-color: #444;
      opacity: 0.2;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1000;
      transition: all 0.3s ease-in;
  }
  .hvp-spinner-wrapper {
      min-width: 150px;
      width: 150px;
      max-width: 180px;
      min-height: 50px;
      height: 50px;
      background-color: #fff;
      position: fixed;
      left: calc(50% - 75px);
      top: calc(50% - 31px);
      z-index: 1200;
      color: #000;
      border-radius: 5px;
      border: 1px #b7b4b4 solid;
      display: table;
      padding: 6px;
  }
  .hvp-data-loader-text-wrapper {
      display: table-cell;
      vertical-align: top;
  }
  .hvp-data-loader-text-wrapper label.hvp-data-loader-text-primary {
      margin: 0 0 0 10px;
      font-size: 0.9rem;
      font-weight: 600;
  }
  .hvp-data-loader-text-wrapper label.hvp-data-loader-text-secondary {
      margin: 0 0 0 10px;
      font-weight: 400;
      color: #898a8a;
      font-size: 0.8rem;
  }
  .hvp-spinner-body {
      display: inline-block;
      vertical-align: middle;
      margin-top: 5px;
      width: 40px;
      height: 40px;
      position: relative;
  }
  .double-bounce1,
  .double-bounce2 {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #ffd446;
      opacity: 0.6;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-animation: hvp-bounce 2s infinite ease-in-out;
      animation: hvp-bounce 2s infinite ease-in-out;
  }
  .double-bounce2 {
      -webkit-animation-delay: -1s;
      animation-delay: -1s;
  }
  @-webkit-keyframes hvp-bounce {
      0%,
      100% {
          -webkit-transform: scale(0);
      }
      50% {
          -webkit-transform: scale(1);
      }
  }
  @keyframes hvp-bounce {
      0%,
      100% {
          transform: scale(0);
          -webkit-transform: scale(0);
      }
      50% {
          transform: scale(1);
          -webkit-transform: scale(1);
      }
  }
  .moreellipses {
      font-weight: 600;
  }
  .morecontent span {
      display: none;
  }
  .morelink {
      color: #da4005 !important;
      display: block;
      font-size: inherit;
      font-weight: 600;
      text-decoration: underline;
  }
  .morelink:hover {
      text-decoration: none;
  }
  .hv-scrollbar::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      background-color: #f5f5f5;
  }
  .hv-scrollbar::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: #f5f5f5;
  }
  .hv-scrollbar::-webkit-scrollbar-thumb {
      border-radius: 8px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      background-color: #b2b2b2;
  }
  #page-action-mask {
      background-color: #000;
      opacity: 0.2;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
      width: 100%;
      height: 100%;
      display: none;
  }
  .hive-logo {
      width: 32px;
      height: 32px;
      margin-right: 10px;
      vertical-align: sub;
  }
  .ping-box {
      display: flex;
      flex-direction: row;
      right: 20px;
      position: absolute;
      bottom: 30px;
      padding: 0;
      height: 150px;
      width: 320px;
      border: 1px solid #888;
      border-radius: 0.3rem;
      background: #fff;
      transition: 0.5s;
  }
  .ping-box.slide {
      right: -285px;
      transition: 0.5s;
  }
  .modal-icon {
      display: none;
      position: fixed;
      z-index: 1;
      padding-top: 50px;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.4);
  }
  .modal-icon-content {
      position: relative;
      display: flex;
      flex-direction: column;
      pointer-events: auto;
      background-clip: padding-box;
      background-color: #fefefe;
      margin: auto;
      padding: 10px 15px;
      border: 1px solid #888;
      border-radius: 0.3rem;
      width: 50%;
  }
  .modal-icon-content .close {
      text-align: right;
  }
  .close:focus,
  .close:hover {
      color: #000;
      text-decoration: none;
      cursor: pointer;
  }
  .modal-icon-content p {
      text-align: center;
  }
  #editIcon {
      margin-right: 10px;
      cursor: pointer;
  }
  .form-info-wrapper .message-wrapper.success {
      background-color: #17b82b30;
      border: 1px #d5f9e2 solid;
  }
  /* .form-info-wrapper .message-wrapper.success .icon {
      background-image: url(../../../assets/img/microsite/app-icons/icon-success-tick_animated.svg);
  } */
  .form-info-wrapper .message-wrapper.success label {
      color: #007b41;
  }
  .connector-line {
      display: flex;
      width: 3px;
      height: 20px;
      background-color: #e3bc17;
      margin-left: 20px;
  }
  .horizontal-line-event-form {
      display: flex;
      width: 100%;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.5);
      margin-top: 10px;
      margin-bottom: 25px;
  }
  .event-form-guide {
      text-align: left !important;
      margin-left: 5px;
  }
  .event-box {
      display: flex;
      flex-direction: column;
      height: auto;
      width: 100%;
      border: 1px solid #88888875;
      border-radius: 0.3rem;
      background: #fff;
  }
  .event-label {
      margin-left: 20px;
      margin-right: 30px;
      padding: 7.5px 0 5px;
      color: #1e692e;
      font-size: 14px;
      font-weight: 700;
  }
  .date-label {
      margin-left: 20px;
      margin-right: 30px;
      padding-bottom: 7.5px;
      font-size: 14px;
  }
  .modal-icon-content h1 {
      border-bottom: 2px solid #e5e5e5;
      font-size: 16px;
      font-weight: 700;
  }
  .modal-icon-content h1:after {
      content: "";
      display: block;
      border-bottom: 2px solid #1e692e;
      margin-bottom: -2px;
      max-width: 50%;
  }
  .ping-header {
      height: 100%;
      width: 40px;
      background-color: #2e8b57;
      align-items: center;
      justify-content: center;
      display: flex;
      cursor: pointer;
      border-top-left-radius: 0.2rem;
      border-bottom-left-radius: 0.2em;
  }
  .ping-header span {
      color: #fff;
      font-weight: 700;
      font-size: 18px;
      writing-mode: vertical-rl;
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
  }
  .ping-content {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding: 5px 10px;
      font-size: 12px !important;
  }
  .ping-btn {
      width: 100%;
      border: 1px solid #888;
      background-color: #fff;
      padding: 8px;
      font-weight: 700;
  }
  .ping-content .search {
      display: flex;
      flex-direction: row;
  }
  .search input {
      width: 85%;
      padding: 0 10px;
      border: 1px solid #888;
  }
  .search button {
      border: 1px solid #888;
      border-left: none;
      width: 15%;
      transition: all 0.3s ease-in-out;
  }
  .search button:hover {
      box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  }
  .ajs-message.ajs-error {
      color: #fff !important;
      border: 1px solid #fff !important;
  }
  @media only screen and (max-width: 600px) {
      .ping-box {
          display: flex;
          flex-direction: row;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
          position: absolute;
          bottom: 30px;
          padding: 0;
          height: 150px;
          width: 320px;
          border: 1px solid #888;
          border-radius: 0.3rem;
          background: #fff;
          transition: 0.5s;
      }
      .modal-icon-content {
          position: relative;
          display: flex;
          flex-direction: column;
          pointer-events: auto;
          background-clip: padding-box;
          background-color: #fefefe;
          margin: auto;
          padding: 10px 15px;
          border: 1px solid #888;
          border-radius: 0.3rem;
          width: 80%;
      }
      #createEventForm .section-primary-title {
          font-size: 24px;
      }
      #searchContentEventModalHtml {
          width: 80% !important;
          margin-top: 30px;
      }
  }
}
body.mcs-html {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif !important;
  height: 100%;
  background-color: #fff;
  min-height: 100%;
  position: relative;

  &.toggle-overflow {
    &, &-desktop {
      overflow-y: hidden !important;
      padding-right: 17px !important;
    }
    &-mobile {
      overflow-y: hidden !important;
    }
  }

  .hv-app-body {
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;
      width: 100%;
      position: relative;
  }
  .hv-app-body.mode-preview {
      margin-top: 50px;
  }
  .hv-app-body.mode-preview .hv-section {
      /* position: relative;
      z-index: 1; */
      width: calc(100% - 10px);
      margin: 0 5px;
  }
  .hv-app-body.mode-preview .hv-section:nth-of-type(odd) {
      background-color: #fafafa;
      border: 1px #dbdbdb solid;
      border-left-width: 0;
      border-right-width: 0;
  }
  .hv-app-body.mode-preview .hv-section:nth-of-type(even) {
      background-color: #fff;
  }
  .hv-app-body.mode-preview .page-action-preview {
      background-color: transparent;
      border: 3px #1e692e solid;
      border-bottom: 0;
      box-shadow: inset 0 0 6px #3e9250;
      position: fixed;
      z-index: 0;
      display: flex;
      flex: 1;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
  }
  .hv-app-body.mode-preview .page-action-preview-border-bottom {
      position: fixed;
      z-index: 1;
      bottom: 0;
      width: 100%;
      height: 3px;
      background-color: #1e692e;
  }
  .hv-app-body.mode-preview #preview-cta-bar {
      height: 50px;
      background-color: #1e692e;
      display: flex;
      flex-direction: row;
      width: 100%;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 5;
      align-items: center;
      justify-content: center;
  }
  .hv-app-body.mode-preview #preview-cta-bar label {
      margin-bottom: 0;
      font-size: 16px;
  }
  .hv-app-body.mode-preview #preview-cta-bar > :not(:last-child) {
      margin-right: 10px;
  }
  .hv-app-body.mode-preview #preview-cta-bar.isMobile {
      height: 90px;
      flex-wrap: wrap;
  }
  .hv-app-body.mode-preview #preview-cta-bar.isMobile label {
      width: 100%;
      text-align: center;
  }
  .hv-app-body.mode-preview #display-settings-bar {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: center;
      position: fixed;
      left: 0;
      bottom: 0;
      z-index: 2;
  }
  .hv-app-body.mode-preview #display-settings-bar .card {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border: 0;
      box-shadow: 0 -2px 6px 1px rgba(0, 0, 0, 0.2);
      overflow: visible;
  }
  .hv-app-body.mode-preview #display-settings-bar .card-header {
      background-color: #1e692e;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding: 0;
  }
  .hv-app-body.mode-preview #display-settings-bar .btn-link {
      color: #fff;
      font-size: 14px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      text-decoration: none;
      position: relative;
      padding-top: 15px;
      padding-bottom: 3px;
  }
  .hv-app-body.mode-preview #display-settings-bar .btn-link.icon::before {
      content: "";
      display: inline-flex;
      width: 16px;
      height: 16px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin-right: 5px;
  }
  .hv-app-body.mode-preview #display-settings-bar .btn-link.icon-settings::before {
      background-image: url(../../../assets/img/microsite/app-icons/icon-settings-white.svg);
  }
  .hv-app-body.mode-preview #display-settings-bar .btn-link.icon::after {
      content: "";
      display: inline-flex;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      position: absolute;
  }
  .hv-app-body.mode-preview #display-settings-bar .btn-link.icon-toggle-arrow::after {
      background-image: url(../../../assets/img/microsite/app-icons/icon-toggle-arrow-white.svg);
      position: absolute;
      left: calc(50% - 10px);
      top: -3px;
      transform: rotate(180deg);
      transition: transform 0.3s ease-in;
  }
  .hv-app-body.mode-preview #display-settings-bar .btn-link.collapsed.icon-toggle-arrow::after {
      transform: rotate(0);
  }
  .hv-app-body.mode-preview #display-settings-bar .dropdown-toggle {
      border-radius: 20px;
      padding: 0.1rem 0.75rem;
  }
  .hv-app-body.mode-preview #display-settings-bar ul.dropdown-menu a.dropdown-item span.text {
      text-transform: capitalize;
  }
  .hv-app-body.mode-preview #display-settings-bar .hv-theme-cover-image-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
  }
  .hv-app-body.mode-preview #display-settings-bar .hv-theme-cover-image-wrapper label.radio-thumbnail {
      margin-bottom: 0;
  }
  .hv-app-body.mode-preview #display-settings-bar .hv-theme-cover-image-wrapper label.radio-thumbnail:not(:last-of-type) {
      margin-right: 10px;
  }
  .hv-app-body.mode-preview #display-settings-bar .hv-theme-cover-image-wrapper label.radio-thumbnail img.img-thumbnail {
      width: 70px;
      height: 70px;
      object-fit: cover;
      cursor: pointer;
      transition: border 0.2s ease-in, box-shadow 0.2s ease-in;
  }
  .hv-app-body.mode-preview #display-settings-bar .hv-theme-cover-image-wrapper label.radio-thumbnail input[type="radio"] {
      display: none;
  }
  .hv-app-body.mode-preview #display-settings-bar .hv-theme-cover-image-wrapper label.radio-thumbnail input[type="radio"]:checked + img {
      border-color: #707070;
      box-shadow: 0 0 6px 0 #707070;
  }
  .hv-jumbotron-banner {
      display: flex;
      flex-direction: row;
      flex: 1;
      min-height: 100vh;
      background-color: #000;
      background-repeat: no-repeat;
      background-position: center;
      transition: background-color 0.5s ease-in, background-image 0.5s ease-in;
  }
  .hv-jumbotron-banner.member-basic {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
  }
  .hv-jumbotron-banner .header-copyright {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
  }
  .hv-jumbotron-banner .header-copyright label {
      color: #fff;
  }
  .hv-jumbotron-banner .header-copyright label a {
      font-weight: 500;
  }
  .hv-section {
      display: flex;
      justify-content: center;
      transition: background-color 0.5s ease-in;
  }
  .hv-section.hv-section-even,
  .hv-section.hv-section-odd {
      display: flex;
      justify-content: center;
  }
  .hv-section.hv-section-odd {
      background-color: #fff;
  }
  .hv-section.hv-section-even {
      background-color: #fafafa;
  }
  .hv-section .section-article-primary {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 35px;
  }
  .hv-section .section-article-form {
      display: flex;
      flex: 1;
      flex-direction: column;
  }
  .hv-section .section-article-form form {
      margin-top: 20px;
  }
  .hv-section .section-carousel-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
  }
  .hv-section .section-video-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 30px 0;
  }
  .hv-section__inner-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
  }
  .hv-section__inner-wrapper.section-two-column {
      flex-direction: row;
      align-items: flex-start;
      justify-content: left;
      flex-wrap: wrap;
  }
  .hv-section__inner-wrapper img.corporate-logo {
      max-width: 140px;
      max-height: 140px;
  }
  .hv-section__inner-wrapper .corporate-mis-vision,
  .hv-section__inner-wrapper .corporate-name,
  .hv-section__inner-wrapper .corporate-presence {
      margin: 0 auto;
      text-align: center;
  }
  .hv-section__inner-wrapper .corporate-name {
      font-size: 32px;
      font-weight: 600;
      color: #1e692e;
      margin-top: 20px;
  }
  .hv-section__inner-wrapper .corporate-mis-vision {
      font-size: 20px;
      font-weight: 200;
      color: #fff;
      margin-bottom: 10px;
      margin-top: 5px;
  }
  .hv-section__inner-wrapper .corporate-presence {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
  }
  .hv-section__inner-wrapper .corporate-presence li {
      font-size: 14px;
      color: #fff;
      padding: 0 10px 0 10px;
      display: flex;
      align-items: center;
      position: relative;
  }
  .hv-section__inner-wrapper .corporate-presence li:last-of-type {
      padding-right: 0;
  }
  .hv-section__inner-wrapper .corporate-presence li:not(:last-of-type)::after {
      content: "";
      background-color: #fff;
      width: 1px;
      height: 70%;
      position: absolute;
      right: 0;
  }
  .hv-section__inner-wrapper .corporate-members {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
  }
  .hv-section__inner-wrapper .corporate-members .corporate-members__item {
      display: flex;
      margin: 0 10px;
      width: 100px;
      height: 90px;
      align-items: center;
      justify-content: center;
      cursor: default;
  }
  .hv-section__inner-wrapper .corporate-members .corporate-members__item:hover,
  .hv-section__inner-wrapper .corporate-members .corporate-members__item:link {
      text-decoration: none;
  }
  .hv-section__inner-wrapper .corporate-members img.members-logo {
      max-width: 100px;
      max-height: 90px;
      object-fit: contain;
  }
  .pills-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 15px;
      margin-bottom: 35px;
  }
  .pills-wrapper label.pills-item {
      color: #1e692e;
      background-color: #fff;
      border: 1px #dbdbdb solid;
      border-radius: 500px;
      display: flex;
      font-size: 16px;
      font-weight: 400;
      margin: 0 6px 10px;
      padding: 3px 12px;
      min-height: 32px;
      text-align: center;
  }
  .pills-wrapper label.pills-item label.pills-item:last-of-type {
      margin-right: 0;
  }
  #awardsAndAccredit .card-body,
  #awardsAndAccredit .card-footer,
  #awardsAndAccredit .card-header {
      background-color: transparent;
  }
  #awardsAndAccredit .card-footer,
  #awardsAndAccredit .card-header {
      border-color: #1e692e;
  }
  #awardsAndAccredit .carousel-inner {
      margin: 0 40px;
      width: calc(100% - 80px);
  }
  #awardsAndAccredit .carousel-control-next,
  #awardsAndAccredit .carousel-control-prev {
      width: 5%;
  }
  #awardsAndAccredit .carousel-control-prev-icon {
      background-image: url(../../../assets/img/microsite/app-icons/icon-carousel-previous.svg);
  }
  #awardsAndAccredit .carousel-control-next-icon {
      background-image: url(../../../assets/img/microsite/app-icons/icon-carousel-next.svg);
  }
  #moreAboutUs .hv-video-card {
      max-height: 300px;
      box-shadow: 0 3px 6px -1px rgba(0, 0, 0, 0.16);
  }
  #moreAboutUs .hv-video-card .card-image {
      height: 100%;
  }
  #moreAboutUs .hv-video-card iframe {
      border-radius: 0.25rem;
  }
  #moreAboutUs .section-carousel-wrapper {
      align-items: flex-start;
  }
  #moreAboutUs .card-body,
  #moreAboutUs .card-footer,
  #moreAboutUs .card-header {
      background-color: transparent;
  }
  #moreAboutUs .card-footer,
  #moreAboutUs .card-header {
      border-color: #1e692e;
  }
  #moreAboutUs .hv-card {
      min-height: 250px;
  }
  #moreAboutUs .hv-card:nth-child(odd) .card-header-band {
      background-image: linear-gradient(to bottom right, #fdf1f1, #b9b9b9);
  }
  #moreAboutUs .hv-card:nth-child(even) .card-header-band {
      background-image: linear-gradient(to bottom right, #fffaea, #efd98c);
  }
  #moreAboutUs .card-header-band {
      width: 100%;
      height: 50px;
      position: absolute;
      z-index: 0;
      left: 0;
      opacity: 0.5;
      top: 0;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
  }
  #moreAboutUs .hv-mgmt-team .stretched-link {
      text-decoration: none !important;
  }
  #moreAboutUs .hv-mgmt-team .profile-pic-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100px;
      margin-bottom: 20px;
  }
  #moreAboutUs .hv-mgmt-team .profile-pic-wrapper .mgmt-member-photo {
      border: 2px #fff solid;
      border-radius: 50%;
      width: 100px;
      height: 100px;
      clip-path: circle(50%);
  }
  #moreAboutUs .hv-mgmt-team .profile-pic-wrapper i.icon {
      width: 100%;
      height: 20px;
      display: flex;
      justify-content: flex-end;
      position: absolute;
      bottom: 0;
      right: 25px;
  }
  #moreAboutUs .hv-mgmt-team .profile-pic-wrapper i.icon.icon-linkedin::after {
      content: "";
      background-color: #fff;
      background-image: url(../../../assets/img/microsite/app-icons/icon-linkedin.svg);
      background-repeat: no-repeat;
      background-size: contain;
      border-radius: 2px;
      width: 20px;
      height: 20px;
      display: flex;
  }
  #moreAboutUs .hv-mgmt-team .mgmt-member-name {
      color: #000;
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 0;
      text-align: center;
  }
  #moreAboutUs .hv-mgmt-team .mgmt-member-title {
      color: #1e692e;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 0;
      text-align: center;
  }
  #moreAboutUs .carousel-control-next,
  #moreAboutUs .carousel-control-prev {
      width: 5%;
  }
  #moreAboutUs .carousel-control-prev-icon {
      background-image: url(../../../assets/img/microsite/app-icons/icon-carousel-previous.svg);
  }
  #moreAboutUs .carousel-control-next-icon {
      background-image: url(../../../assets/img/microsite/app-icons/icon-carousel-next.svg);
  }
  #contactUs .contact-us-form .hv-form-check {
      padding-left: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
  }
  #contactUs .contact-us-form .hv-form-check span {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: left;
  }
  #contactUs .contact-us-form .hv-form-check .form-check-label {
      cursor: pointer;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-left: 1.5rem;
      margin-right: 1rem;
  }
  #contactUs .contact-us-form .hv-form-check .form-check-label:not(:last-of-type) {
      margin-right: 30px;
  }
  #contactUs .contact-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      height: 100%;
  }
  #contactUs .contact-info .address {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: left;
      padding: 5px 0;
  }
  #contactUs .contact-info .address .address-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: left;
  }
  #contactUs .contact-info .address a,
  #contactUs .contact-info .address label {
      color: #1e692e;
      display: block;
      font-size: 16px;
      margin: 0 0 0 10px;
      width: 100%;
      word-break: break-word;
  }
  #contactUs .contact-info .address label > a {
      margin-left: 0;
  }
  #contactUs .contact-info .address.icon::before {
      content: "";
      width: 24px;
      height: 24px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: block;
  }
  #contactUs .contact-info .address.icon-mobile::before {
      background-image: url(../../../assets/img/microsite/app-icons/icon-phone-green.svg);
  }
  #contactUs .contact-info .address.icon-email::before {
      background-image: url(../../../assets/img/microsite/app-icons/icon-email-green.svg);
  }
  #contactUs .contact-info .address.icon-location::before {
      background-image: url(../../../assets/img/microsite/app-icons/icon-location-pin-green.svg);
  }
  #contactUs .contact-info .address.icon-web::before {
      background-image: url(../../../assets/img/microsite/app-icons/icon-web-green.svg);
  }
  #getsServicesUsed .section-carousel-wrapper {
      min-height: 250px;
  }
  #getsServicesUsed .hv-card {
      height: 100%;
      margin-bottom: 10px;
      width: 220px;
  }
  .hv-card {
      width: 280px;
      box-shadow: 0 3px 6px -1px rgba(0, 0, 0, 0.16);
      margin: 0 10px;
  }
  .hv-card.has-focus {
      cursor: pointer;
      transition: box-shadow 0.2s ease-in;
  }
  .hv-card.has-focus:hover {
      box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.3);
  }
  .hv-card .card-text {
      font-size: 14px;
      color: #333;
      text-align: center;
  }
  .hv-card .service-logo-wrapper {
      display: flex;
      height: 70px;
      align-items: center;
      justify-content: center;
  }
  .hv-card .service-logo-wrapper img.service-logo {
      max-width: 120px;
      max-height: 60px;
  }
  .section-icon {
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      left: calc(50% - 40px);
  }
  .section-icon::after {
      content: "";
      display: flex;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
  }
  .section-icon.icon-industry-services::after {
      background-image: url(../../../assets/img/microsite/app-icons/icon-industries-services.svg);
      width: 60px;
      height: 60px;
  }
  .section-icon.icon-awards-accredit::after {
      background-image: url(../../../assets/img/microsite/app-icons/icon-awards.svg);
      width: 40px;
      height: 60px;
  }
  .section-icon.icon-about-us::after {
      background-image: url(../../../assets/img/microsite/app-icons/icon-about-us.svg);
      width: 70px;
      height: 60px;
  }
  .section-icon.icon-our-interests::after {
      background-image: url(../../../assets/img/microsite/app-icons/icon-geographic.svg);
      width: 100px;
      height: 60px;
  }
  .section-icon.icon-contact-us::after {
      background-image: url(../../../assets/img/microsite/app-icons/icon-contact-us.svg);
      width: 60px;
      height: 60px;
  }
  .section-icon.icon-gets-services::after {
      background-image: url(../../../assets/img/microsite/app-icons/icon-gets-services.svg);
      width: 60px;
      height: 60px;
  }
  .hv-primary-navbar {
      display: flex;
      position: absolute;
      top: 20px;
      padding: 0;
      z-index: 2;
  }
  .hv-primary-navbar .nav {
      width: 320px;
      display: flex;
      flex-direction: column;
  }
  .hv-primary-navbar .nav .nav-item {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      color: #fff;
  }
  .hv-primary-navbar .nav .nav-link {
      color: #fff;
      font-size: 16px;
      font-weight: 300;
      text-align: right;
      word-break: break-word;
      padding: 5px 0;
      cursor: pointer;
  }
  .hv-primary-navbar .nav .nav-link:hover {
      color: #1e692e !important;
      text-decoration: underline;
  }
  .hv-primary-navbar .nav .nav-link.active {
      background-color: transparent;
      color: #1e692e;
      font-weight: 700 !important;
      text-decoration: underline;
  }
  .hv-primary-navbar.isMobile .nav-list-wrapper {
      flex: 1;
      overflow: auto;
      margin-bottom: 40px;
      width: 100%;
  }
  .hv-primary-navbar.isMobile .app-copyright {
      position: absolute;
      bottom: 0;
      height: 40px;
      background-color: #fff;
      z-index: 1;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
  }
  .hv-primary-navbar.isMobile .app-copyright label {
      margin: 0;
  }
  .hv-primary-navbar.isMobile a.nav-icon::before {
      content: "";
      background-repeat: no-repeat;
      background-size: contain;
      display: flex;
      width: 24px;
      height: 24px;
      margin-right: 10px;
  }
  .hv-primary-navbar.isMobile a.nav-icon-home::before {
      background-image: url(../../../assets/img/microsite/app-icons/nav-icon-home.svg);
  }
  .hv-primary-navbar.isMobile a.nav-icon-industries-services::before {
      background-image: url(../../../assets/img/microsite/app-icons/nav-icon-industries-services.svg);
  }
  .hv-primary-navbar.isMobile a.nav-icon-awards-accredit::before {
      background-image: url(../../../assets/img/microsite/app-icons/nav-icon-awards-accredit.svg);
  }
  .hv-primary-navbar.isMobile a.nav-icon-about-us::before {
      background-image: url(../../../assets/img/microsite/app-icons/nav-icon-about-us.svg);
  }
  .hv-primary-navbar.isMobile a.nav-icon-our-interest::before {
      background-image: url(../../../assets/img/microsite/app-icons/nav-icon-our-interests.svg);
  }
  .hv-primary-navbar.isMobile a.nav-icon-contact-us::before {
      background-image: url(../../../assets/img/microsite/app-icons/nav-icon-contact-us.svg);
  }
  .hv-primary-navbar.isMobile a.nav-icon-services-used::before {
      background-image: url(../../../assets/img/microsite/app-icons/nav-icon-services-used.svg);
  }

  .hv-primary-navbar.isMobile a.nav-icon-edit-profile::before {
      background-image: url(../../../assets/img/microsite/app-icons/nav-icon-edit-app.svg);
  }
  .hv-primary-navbar .list-group {
      display: flex;
  }
  .hv-primary-navbar .list-group .list-group__header {
      width: 100%;
      display: flex;
      padding: 10px 10px 10px 20px;
      margin-top: 10px;
      border-top: 1px rgba(0, 0, 0, 0.2) solid;
      background-color: rgba(194, 194, 194, 0.1);
      border-bottom: 1px rgba(0, 0, 0, 0.2) solid;
      margin-bottom: 0;
  }
  .hv-primary-navbar .list-group ul {
      margin: 0;
      padding: 0;
  }
  .corporate-name-wrapper {
      background-color: #fafafa;
      border-bottom: 1px #ccc solid;
      width: 100%;
      height: 60px;
      align-items: center;
      justify-content: left;
  }
  .corporate-name-wrapper .corporate-name {
      font-size: 20px;
      color: #1e692e;
      position: relative;
      left: 50px;
      white-space: nowrap;
      width: 70%;
      text-overflow: ellipsis;
      margin-bottom: 0;
  }
  #app-brandbar {
      height: 60px;
  }

  #app-brandbar.mode-preview {
      width: calc(100% - 6px);
      margin: 0 3px;
  }

  #hamburger-icon {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 9999;
      -webkit-transform: rotate(0);
      -moz-transform: rotate(0);
      -o-transform: rotate(0);
      transform: rotate(0);
      -webkit-transition: 0.5s ease-in-out;
      -moz-transition: 0.5s ease-in-out;
      -o-transition: 0.5s ease-in-out;
      transition: 0.5s ease-in-out;
      cursor: pointer;
      float: left;
      display: none;
  }
  #hamburger-icon span {
      display: block;
      position: absolute;
      height: 4px;
      width: 100%;
      background: #fff;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0);
      -moz-transform: rotate(0);
      -o-transform: rotate(0);
      transform: rotate(0);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
      box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);
  }
  #hamburger-icon.open {
      z-index: 999999;
  }
  #hamburger-icon.open span {
      background: #f99601;
      box-shadow: none;
  }
  #hamburger-icon.open span:nth-child(1) {
      top: 9px;
      width: 0%;
      left: 50%;
  }
  #hamburger-icon.open span:nth-child(2) {
      top: 12px;
  }
  #hamburger-icon.open span:nth-child(3) {
      top: 3px;
  }
  #hamburger-icon.open span:nth-child(2) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      width: 60%;
  }
  #hamburger-icon.open span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      width: 60%;
  }
  #hamburger-icon.open span:nth-child(4) {
      top: 9px;
      width: 0%;
      left: 50%;
  }
  #hamburger-icon span:nth-child(1) {
      top: 0;
  }
  #hamburger-icon span:nth-child(2),
  #hamburger-icon span:nth-child(3) {
      top: 9px;
  }
  #hamburger-icon span:nth-child(4) {
      top: 18px;
  }
  #hv-app-footer {
      background-color: #333;
      display: flex;
      height: 60px;
      width: 100%;
  }
  #hv-app-footer .hv-footer-links {
      margin: 15px 20px 15px;
      padding: 0;
      display: flex;
      flex-direction: row;
      list-style: none;
  }
  #hv-app-footer .hv-footer-links li {
      font-size: 14px;
      color: #fff;
      padding: 0 10px 0 10px;
      display: flex;
      align-items: center;
      position: relative;
  }
  #hv-app-footer .hv-footer-links li:last-of-type {
      padding-right: 0;
  }
  #hv-app-footer .hv-footer-links li:not(:last-of-type)::after {
      content: "";
      background-color: #fff;
      width: 1px;
      height: 70%;
      position: absolute;
      right: 0;
  }
  .action-go-to-top {
      background-color: #000;
      border-radius: 50%;
      position: fixed;
      bottom: 0;
      right: 20px;
      width: 40px;
      height: 40px;
      opacity: 0;
      color: #fff;
      box-shadow: 0 5px 6px -1px rgba(0, 0, 0, 0.5);
      transition: bottom 1s, opacity 0.5s ease-in;
      z-index: 999999;
  }
  .action-go-to-top::after {
      content: "Top";
      color: #fff;
      position: absolute;
      top: 14px;
      left: 9px;
      font-size: 12px;
  }
  .action-go-to-top i {
      padding: 0 10px;
      color: #707070;
      position: relative;
      top: -1px;
  }
  .action-go-to-top i.icon {
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-size: contain;
      left: 10px;
  }
  .action-go-to-top i.icon.icon-goto-top-arrow {
      background-image: url(../../../assets/img/microsite/app-icons/icon-goto-top-arrow-white.svg);
  }
  .action-go-to-top.action-go-to-top-active {
      background-color: #000;
      bottom: 20px;
      opacity: 1;
      transition: background-color 1s, bottom 1s, opacity 2s;
  }
  .action-go-to-top:hover {
      color: #f99601;
      bottom: 30px;
  }
  .action-go-to-top.action-go-to-top-blur {
      bottom: 0;
      opacity: 0;
      transition: bottom 1s, opacity 2s;
  }
  ol.carousel-indicators {
      margin-top: 20px;
      display: flex;
      height: 16px;
      position: relative;
      justify-content: center;
  }
  ol.carousel-indicators li {
      background-color: transparent;
      border: 1px #1e692e solid;
      border-radius: 50%;
      box-shadow: 0 2px 6px -1px rgba(0, 0, 0, 0.2);
      cursor: pointer;
      position: relative;
      width: 16px;
      height: 16px;
      transition: background-color 1s, border 1s;
      margin: 0 5px;
  }
  ol.carousel-indicators li.active {
      background-color: #1e692e;
  }
  .bootstrap-select .dropdown-menu {
      border-color: #000;
      z-index: 10;
  }
  .bootstrap-select .dropdown-menu .dropdown-item {
      border-radius: 3px;
      width: calc(100% - 8px);
      margin: 0 4px;
      padding: 0.25rem 0.75rem;
  }
  .bootstrap-select .dropdown-menu .dropdown-item.active,
  .bootstrap-select .dropdown-menu .dropdown-item:active {
      background-color: #cff3d6;
      color: #000;
      font-weight: 600;
  }
  .bootstrap-select .dropdown-menu .dropdown-item span.text {
      word-break: break-word;
      width: 100%;
      white-space: normal;
  }
  .bootstrap-select:not(.dd-full-width) .filter-option {
      width: 80% !important;
  }
  .bootstrap-select.dd-full-width .dropdown-item,
  .bootstrap-select.dd-full-width .filter-option {
      text-align: center;
  }
  @keyframes createBox {
      from {
          opacity: 0;
          transform: scalex(0);
      }
      to {
          opacity: 1;
          transform: scalex(1);
      }
  }
  @keyframes fadeInDown {
      0% {
          opacity: 0;
          transform: translateY(-20px);
      }
      100% {
          opacity: 1;
          transform: translateY(0);
      }
  }
  .app-popup {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 999;
      display: flex;
      flex-direction: column;
  }
  .app-popup .app-popup-header {
      height: 75px;
      display: flex;
      background-color: transparent;
  }
  .app-popup .app-popup-body {
      border: 1px #333 solid;
      box-sizing: border-box;
      color: #333;
      flex: 1;
      display: flex;
      flex-direction: column;
      position: relative;
      height: 100%;
  }
  .app-popup .app-popup-body .app-popup-overlay {
      background-color: #fff;
      display: flex;
      flex: 1;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
  }
  .app-popup .app-popup-body .app-popup-content {
      display: flex;
      flex: 1;
      padding: 30px 12px 12px 12px;
      box-sizing: border-box;
      position: relative;
      overflow: hidden;
  }
  .app-popup .app-popup-body .popup-header-action-bar {
      position: absolute;
      right: 3px;
      top: 3px;
  }
  .app-popup .app-popup-body .popup-content__inner {
      overflow: auto;
      flex: 1;
      display: flex;
      padding: 0 10px;
      box-sizing: border-box;
  }
  .app-popup .app-popup-body .popup-content__inner .spinner {
      display: none;
  }
  .app-popup .app-popup-body .popup-content__inner.js-please-wait {
      align-items: center;
      justify-content: center;
  }
  .app-popup .app-popup-body .popup-content__inner.js-please-wait .spinner {
      display: block;
  }
  .scale-up-center {
      -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  @-webkit-keyframes scale-up-center {
      0% {
          -webkit-transform: scale(0.5);
          transform: scale(0.5);
      }
      100% {
          -webkit-transform: scale(1);
          transform: scale(1);
      }
  }
  @keyframes scale-up-center {
      0% {
          -webkit-transform: scale(0.5);
          transform: scale(0.5);
      }
      100% {
          -webkit-transform: scale(1);
          transform: scale(1);
      }
  }
  .scale-down-center {
      -webkit-animation: scale-down-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation: scale-down-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  @-webkit-keyframes scale-down-center {
      0% {
          -webkit-transform: scale(1);
          transform: scale(1);
      }
      100% {
          -webkit-transform: scale(0.5);
          transform: scale(0.5);
      }
  }
  @keyframes scale-down-center {
      0% {
          -webkit-transform: scale(1);
          transform: scale(1);
      }
      100% {
          -webkit-transform: scale(0.5);
          transform: scale(0.5);
      }
  }
  .btn-icon.icon-settings {
      background-image: url(../../../assets/img/microsite/app-icons/icon-app-settings-yellow.svg);
  }
  .hv-nav-dropdown-item {
      display: inline-flex;
      align-items: center;
      padding: 0.25rem 0.75rem;
  }

  .award-doc {
      max-width: 100px;
      max-height: 100px;
  }

  .card-media {
      display: flex;
      justify-content: center;
  }

  .Carousel-root-4 {
      overflow: visible;
  }
  .Carousel-next-14 {
      right: -20px;
  }

  .display-modal {
      display: block;
  }

  .hv-settings-accordion.MuiAccordion-rounded:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      margin-bottom: 0px;
  }

  .hv-settings-accordion .MuiSelect-select.MuiSelect-select {
      padding-left: 30px;
  }
  .theme-control .MuiSelect-select.MuiSelect-select {
      margin-top: -2px;
  }

  #microsite-area-code-label, #microsite-location-code-label {
      color: #1C1D2157;
  }
}
