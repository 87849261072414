@charset "utf-8";
body.mcs-html {
  .hv-app-body.hv-theme-light .hv-section:nth-of-type(odd) {
      background-color: #fcfffd !important;
      border: 1px #e1f5e6 solid !important;
      border-left-width: 0 !important;
      border-right-width: 0 !important;
  }
  .hv-app-body.hv-theme-light .hv-section:nth-of-type(even) {
      background-color: #fff !important;
  }
  .hv-app-body.hv-theme-light .hv-jumbotron-banner {
      background-color: #f2fff6;
      border-bottom: 1px #e1f5e6 solid;
      background-image: url('../../../assets/img/microsite/hero/light-bg@2x.png') !important;
  }
  .hv-app-body.hv-theme-light .header-copyright label {
      color: #000;
  }
  .hv-app-body.hv-theme-light .header-copyright label a {
      color: #0c4e1a !important;
  }
  .hv-app-body.hv-theme-light .corporate-name-wrapper .corporate-mis-vision,
  .hv-app-body.hv-theme-light .corporate-name-wrapper .corporate-name,
  .hv-app-body.hv-theme-light .corporate-primary .corporate-mis-vision,
  .hv-app-body.hv-theme-light .corporate-primary .corporate-name {
      color: #1e692e !important;
  }
  .hv-app-body.hv-theme-light .hv-section__inner-wrapper .section-primary-title {
      color: #1e692e !important;
  }
  .hv-app-body.hv-theme-light .hv-section__inner-wrapper .section-secondary-title {
      color: #27893c !important;
  }
  .hv-app-body.hv-theme-light .hv-section__inner-wrapper .corporate-mis-vision,
  .hv-app-body.hv-theme-light .hv-section__inner-wrapper .corporate-name {
      color: #0c4e1a !important;
  }
  .hv-app-body.hv-theme-light .hv-section__inner-wrapper .corporate-presence li {
      color: #000;
  }
  .hv-app-body.hv-theme-light .hv-section__inner-wrapper .corporate-presence li:not(:last-of-type)::after {
      background-color: #333;
  }
  .hv-app-body.hv-theme-light .theme-home-text {
      color: #000;
  }
  .hv-app-body.hv-theme-light .menu-divider::before {
      color: #000;
  }
  .hv-primary-navbar.hv-theme-light .nav-link {
      color: #000;
      font-weight: 400;
  }
  .hv-primary-navbar.hv-theme-light .nav-link:hover {
      color: #0c4e1a !important;
  }
  .hv-primary-navbar.hv-theme-light .nav-link.active {
      color: #0c4e1a !important;
      font-weight: 500;
  }
  .hv-theme-light .theme-select-dd {
      background-color: rgba(255, 255, 255, 0) !important;
      border-color: #000 !important;
  }
  .hv-theme-light .theme-select-dd .btn.dropdown-toggle {
      background-color: transparent !important;
      border-color: #000 !important;
  }
  .hv-theme-light .theme-select-dd .btn.dropdown-toggle .filter-option-inner-inner {
      color: #000 !important;
  }
  .hv-theme-light .theme-select-dd .btn.dropdown-toggle::after {
      color: #000 !important;
  }
  .hv-theme-light .btn.icon-button-transparent {
      background-color: rgba(255, 255, 255, 0) !important;
      border-color: #000 !important;
  }
  .hv-theme-light .btn.icon-button-transparent::after {
      color: #000 !important;
  }
}
