@media screen and (min-width: 576px) {
    #awardsAndAccredit .section-carousel-wrapper {
        width: 80%;
    }
}
@media screen and (min-width: 768px) {
    .hv-primary-navbar {
        right: 20px;
    }
    .hv-section__inner-wrapper .corporate-mis-vision,
    .hv-section__inner-wrapper .corporate-name,
    .hv-section__inner-wrapper .corporate-presence {
        width: 70%;
    }
    .hv-section {
        padding: 40px 40px;
    }
    .hv-section.hv-section-even,
    .hv-section.hv-section-odd {
        padding: 40px 40px;
    }
    .hv-mobile-only {
        display: none !important;
    }
    .hv-desktop-only {
        display: inline-flex;
    }
    #awardsAndAccredit .section-carousel-wrapper {
        width: 80%;
    }
    #awardsAndAccredit .hv-card {
        margin: 0 40px;
        width: calc(100% - 80px);
        background-color: transparent !important;
        border: 0;
        box-shadow: none;
    }
    #moreAboutUs .carousel-inner {
        margin: 0 40px;
        width: calc(100% - 80px);
    }
    #moreAboutUs .section-video-wrapper {
        width: 80%;
    }
    #moreAboutUs .hv-video-card {
        min-width: 450px;
        max-width: 550px;
    }
    #moreAboutUs .hv-card {
        width: 180px;
        margin: 0 10px 10px;
    }
    #contactUs .hv-section__inner-wrapper {
        flex-direction: row;
        align-items: flex-start;
        justify-content: left;
        flex-wrap: wrap;
    }
    #contactUs .contact-info {
        width: 35%;
    }
    #display-settings-bar .hv-settings-accordion {
        width: 300px;
    }
    .app-toolbar-nav-list .nav-list-item {
        margin: 0 0 0 10px;
    }
}
@media screen and (min-width: 992px) {
    #awardsAndAccredit .section-carousel-wrapper {
        width: 100%;
    }
    .app-toolbar-nav-list .nav-list-item {
        margin: 0 0 0 15px;
    }
    .app-toolbar-nav-list .nav-list-item:first-child {
        margin-left: 0;
    }
}
@media screen and (min-width: 1200px) {
    #awardsAndAccredit .section-carousel-wrapper {
        width: 60%;
    }
}
@media screen and (max-width: 576px) {
    .hv-section__inner-wrapper .corporate-mis-vision,
    .hv-section__inner-wrapper .corporate-name {
        width: 100%;
    }
    #preview-cta-bar {
        height: 90px !important;
        flex-wrap: wrap;
    }
    #preview-cta-bar label {
        width: 100%;
        text-align: center;
    }
    #hamburger-icon.mode-preview {
        top: 110px !important;
    }
    #hamburger-icon.mode-preview.open {
        top: 20px !important;
    }
    #mobileToolBar.mode-preview {
        top: 105px !important;
    }
    #app-brandbar.mode-preview {
        top: 90px !important;
    }
    /* .hv-primary-navbar {
        flex-direction: column;
    } */
    #contactUs .address {
        margin: 0 5px;
    }
    #display-settings-bar .hv-settings-accordion {
        width: 80%;
    }
}
@media screen and (max-width: 767px) {
    #app-brandbar.sticky-header {
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        position: fixed;
        top: 0;
        z-index: 20;
        width: 100vw;
        height: 60px;
        animation-name: fadeInDown;
        animation-duration: 0.4s;
    }
    .hv-primary-navbar {
        align-items: flex-start;
        justify-content: left;
        position: fixed;
        left: -80%;
        top: 0;
        height: 100vh;
        background-color: #fff;
        width: 80%;
        z-index: -1;
        opacity: 0;
        -webkit-transition: all 350ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
        transition: all 350ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
        flex-direction: column;
    }
    #hv-primary-nav.active {
        left: 0;
        opacity: 1;
        z-index: 99999;
    }
    #hv-primary-nav .nav {
        width: 100%;
    }
    #hv-primary-nav .nav .nav-item {
        justify-content: left;
    }
    #hv-primary-nav .nav .nav-link {
        color: #000;
        font-weight: 400;
        display: flex;
        width: 100%;
        text-align: left;
        padding: 15px 10px 15px 20px;
        position: relative;
    }
    #hv-primary-nav .nav .nav-link::after {
        content: "";
        display: flex;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.1);
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        transform: scaleX(0);
    }
    #hv-primary-nav .nav .nav-link.active,
    #hv-primary-nav .nav .nav-link:active,
    #hv-primary-nav .nav .nav-link:focus {
        color: #1e692e;
    }
    #hv-primary-nav .nav .nav-link.active::after,
    #hv-primary-nav .nav .nav-link:active::after,
    #hv-primary-nav .nav .nav-link:focus::after {
        animation: createBox 0.5s;
    }
    #hv-primary-nav .app-copyright {
        position: absolute;
        bottom: 0;
        height: 40px;
        background-color: #fff;
        z-index: 1;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    #hv-primary-nav .app-copyright::before {
        content: "";
        width: 100%;
        height: 2px;
        background-image: linear-gradient(to right, #fff, rgba(0, 0, 0, 0.1), #fff);
        position: absolute;
        top: 0;
        left: 0;
    }
    #hv-primary-nav .app-copyright label {
        margin: 0;
    }
    #hv-header-copyright {
        display: none;
    }
    #hamburger-icon {
        display: block;
        position: fixed;
    }
    #hamburger-icon.mode-preview {
        top: 70px;
    }
    #hamburger-icon.mode-preview.open {
        top: 20px;
    }
    #mobileToolBar {
        display: inline-flex;
        position: fixed;
    }
    #mobileToolBar.mode-preview {
        top: 65px;
    }
    #mobileToolBar .btn-icon.icon-bell-alerts,
    #mobileToolBar .btn-icon.icon-bell-no-alerts {
        position: absolute;
        right: 20px;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.2);
        background-size: 24px;
        background-position: center;
    }
    #mobileToolBar .mobile-settings-select,
    #mobileToolBar .mobile-theme-select {
        position: absolute;
        right: 15px;
    }
    #mobileToolBar.hv-theme-blue .btn-icon.icon-bell-alerts,
    #mobileToolBar.hv-theme-bright .btn-icon.icon-bell-alerts,
    #mobileToolBar.hv-theme-dark .btn-icon.icon-bell-alerts,
    #mobileToolBar.hv-theme-default .btn-icon.icon-bell-alerts,
    #mobileToolBar.hv-theme-light .btn-icon.icon-bell-alerts {
        background-image: url(../../../assets/img/logo-gets.svg);
    }
    #mobileToolBar.hv-theme-blue .btn-icon.icon-bell-no-alerts,
    #mobileToolBar.hv-theme-bright .btn-icon.icon-bell-no-alerts,
    #mobileToolBar.hv-theme-dark .btn-icon.icon-bell-no-alerts,
    #mobileToolBar.hv-theme-default .btn-icon.icon-bell-no-alerts,
    #mobileToolBar.hv-theme-light .btn-icon.icon-bell-no-alerts {
        background-image: url(../../../assets/img/logo-gets.svg);
    }
    #mobileToolBar.hv-theme-blue .btn.icon-button-transparent,
    #mobileToolBar.hv-theme-bright .btn.icon-button-transparent,
    #mobileToolBar.hv-theme-dark .btn.icon-button-transparent,
    #mobileToolBar.hv-theme-default .btn.icon-button-transparent,
    #mobileToolBar.hv-theme-light .btn.icon-button-transparent {
        border-color: #fff !important;
    }
    #mobileToolBar.hv-theme-blue .btn.icon-button-transparent:active,
    #mobileToolBar.hv-theme-blue .btn.icon-button-transparent:focus,
    #mobileToolBar.hv-theme-bright .btn.icon-button-transparent:active,
    #mobileToolBar.hv-theme-bright .btn.icon-button-transparent:focus,
    #mobileToolBar.hv-theme-dark .btn.icon-button-transparent:active,
    #mobileToolBar.hv-theme-dark .btn.icon-button-transparent:focus,
    #mobileToolBar.hv-theme-default .btn.icon-button-transparent:active,
    #mobileToolBar.hv-theme-default .btn.icon-button-transparent:focus,
    #mobileToolBar.hv-theme-light .btn.icon-button-transparent:active,
    #mobileToolBar.hv-theme-light .btn.icon-button-transparent:focus {
        border-color: #fff !important;
        box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.3) !important;
    }
    #mobileToolBar.hv-theme-blue .btn.icon-button-transparent::after,
    #mobileToolBar.hv-theme-bright .btn.icon-button-transparent::after,
    #mobileToolBar.hv-theme-dark .btn.icon-button-transparent::after,
    #mobileToolBar.hv-theme-default .btn.icon-button-transparent::after,
    #mobileToolBar.hv-theme-light .btn.icon-button-transparent::after {
        color: #fff;
    }
    #app-brandbar.mode-preview {
        top: 50px;
    }
    .hv-section {
        padding: 60px 0;
    }
    .hv-section.hv-section-even,
    .hv-section.hv-section-odd {
        padding: 60px 0;
    }
    #awardsAndAccredit .section-carousel-wrapper,
    #getsServicesUsed .section-carousel-wrapper {
        flex-direction: column;
    }
    #awardsAndAccredit .carousel-inner {
        margin: 0 10px;
        width: calc(100% - 20px);
    }
    #awardsAndAccredit .carousel-item {
        padding-bottom: 10px;
    }
    #awardsAndAccredit .section-carousel-wrapper {
        width: 100%;
    }
    #awardsAndAccredit .hv-card {
        border-radius: 10px;
        margin: 0 10px;
        width: calc(100% - 20px);
    }
    #awardsAndAccredit .card-header {
        min-height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    #awardsAndAccredit .card-body {
        min-height: 140px;
    }
    #awardsAndAccredit .card-footer {
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    #moreAboutUs .section-primary-description {
        margin: 0 10px;
    }
    #moreAboutUs .section-video-wrapper {
        width: 100%;
    }
    #moreAboutUs .hv-video-card {
        width: 100% !important;
    }
    #moreAboutUs .hv-card {
        width: calc(50% - 10px);
        margin: 5px 5px;
        box-shadow: 0 3px 4px -1px rgba(0, 0, 0, 0.16);
    }
    #contactUs .hv-section__inner-wrapper {
        flex-direction: column-reverse;
        justify-content: left;
        flex-wrap: wrap;
        height: auto;
    }
    #contactUs .vertical-divider-line {
        display: none;
    }
    #contactUs .contact-info {
        width: calc(100% - 20px);
    }
    #contactUs article {
        margin: 0 10px;
    }
    #contactUs .address {
        margin: 0 45px;
    }
    .hv-mobile-only {
        display: inline-flex;
    }
    .hv-desktop-only {
        display: none;
    }
    #hv-app-footer .hv-footer-links {
        justify-content: center;
    }
    .country-code-dd-fix button.dropdown-toggle {
        min-width: calc(55vw - 50px) !important;
        max-width: calc(55vw - 50px) !important;
    }
    .country-code-dd-fix .dropdown-menu {
        min-width: calc(100vw - 80px) !important;
        max-width: calc(100vw - 80px) !important;
    }
    #display-settings-bar .hv-settings-accordion {
        width: 80%;
    }
    .nav-list-wrapper {
        flex: 1;
        overflow: auto;
        margin-bottom: 40px;
        width: 100%;
    }
}
