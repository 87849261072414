@charset "utf-8";
.hv-jumbotron-banner .header-copyright__wrappers {
    display: flex;
    align-items: center;
    height: 32px;
    margin: 20px 0 0 10px;
}
.hv-jumbotron-banner .header-copyright__wrappers label {
    margin: 0;
}
#mobileToolBar {
    display: inline-flex;
    flex-direction: row;
    position: absolute;
    top: 20px;
    right: 0;
    z-index: 9999;
    float: left;
    display: none;
    width: calc(100% - 60px);
    margin-left: 60px;
    cursor: default;
}
.hv-toast {
    background-color: #fff;
    max-width: 300px;
    margin-top: 8px;
    position: absolute;
    overflow: unset;
    border-radius: 5px;
    z-index: 99;
}
.hv-toast::after,
.hv-toast::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-top: 0;
    position: absolute;
    top: -8px;
    left: 12px;
}
.hv-toast.mobile-view::after,
.hv-toast.mobile-view::before {
    left: unset;
    right: 12px;
}
.hv-toast::before {
    border-right: 8px transparent solid;
    border-bottom: 8px #ddf8e3 solid;
    border-left: 8px transparent solid;
    z-index: 1;
}
.hv-toast::after {
    border-right: 8px transparent solid;
    border-bottom: 8px rgba(0, 0, 0, 0.5) solid;
    border-left: 8px transparent solid;
    z-index: 0;
}
.hv-toast .toast-header {
    background-color: #ddf8e3;
    border-bottom-color: #b3efc0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 30px 10px;
    position: relative;
}
.hv-toast .toast-header .title {
    color: #1e692e;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    text-align: center;
}
.hv-toast .toast-body {
    background-color: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 10px 30px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.hv-toast .toast-body p {
    color: #333;
    font-size: 16px;
    text-align: center;
}
.hv-toast .toast-body .link-btn {
    font-weight: 400;
    text-decoration: underline;
}
.hv-toast .toast-body .hv-btn {
    margin: 10px 0;
    min-width: 90px;
}
