@charset "utf-8";
body.mcs-html {
  .hv-app-body.hv-theme-default .hv-section:nth-of-type(odd) {
      background-color: #fafafa !important;
      border: 1px #dbdbdb solid !important;
      border-left-width: 0 !important;
      border-right-width: 0 !important;
  }
  .hv-app-body.hv-theme-default .hv-section:nth-of-type(even) {
      background-color: #fff !important;
  }
  .hv-app-body.hv-theme-default .hv-jumbotron-banner {
      background-color: #000;
      background-image: url('../../../assets/img/microsite/hero/default-bg@2x.png') !important;
  }
  .hv-app-body.hv-theme-default .header-copyright label {
      color: #fff;
  }
  .hv-app-body.hv-theme-default .header-copyright label a {
      color: #33bf50 !important;
  }
  .hv-app-body.hv-theme-default .corporate-name-wrapper .corporate-mis-vision,
  .hv-app-body.hv-theme-default .corporate-name-wrapper .corporate-name,
  .hv-app-body.hv-theme-default .corporate-primary .corporate-mis-vision,
  .hv-app-body.hv-theme-default .corporate-primary .corporate-name {
      color: #1e692e !important;
  }
  .hv-app-body.hv-theme-default .hv-section__inner-wrapper .section-primary-title {
      color: #1e692e !important;
  }
  .hv-app-body.hv-theme-default .hv-section__inner-wrapper .section-secondary-title {
      color: #27893c !important;
  }
  .hv-app-body.hv-theme-default .hv-section__inner-wrapper .corporate-mis-vision,
  .hv-app-body.hv-theme-default .hv-section__inner-wrapper .corporate-name {
      color: #33bf50 !important;
  }
  .hv-app-body.hv-theme-default .hv-section__inner-wrapper .corporate-presence li {
      color: #fff;
  }
  .hv-app-body.hv-theme-default .hv-section__inner-wrapper .corporate-presence li:not(:last-of-type)::after {
      background-color: #fff;
  }
  .hv-app-body.hv-theme-default .theme-home-text {
      color: #fff;
  }
  .hv-app-body.hv-theme-default .nav-link {
      color: #fff;
  }
  .hv-app-body.hv-theme-default .nav-link:hover {
      color: #33bf50 !important;
  }
  .hv-app-body.hv-theme-default .nav-link.active {
      color: #33bf50 !important;
      font-weight: 500;
  }
  .hv-primary-navbar.hv-theme-default .nav-link {
      color: #fff;
  }
  .hv-primary-navbar.hv-theme-default .nav-link:hover {
      color: #33bf50 !important;
  }
  .hv-primary-navbar.hv-theme-default .nav-link.active {
      color: #33bf50 !important;
  }
  .hv-theme-default .theme-select-dd {
      background-color: transparent !important;
      border-color: #e3bc17 !important;
  }
  .hv-theme-default .theme-select-dd .btn.dropdown-toggle {
      background-color: transparent !important;
      border-color: #e3bc17 !important;
  }
  .hv-theme-default .theme-select-dd .btn.dropdown-toggle .filter-option-inner-inner {
      color: #e3bc17 !important;
  }
  .hv-theme-default .theme-select-dd .btn.dropdown-toggle::after {
      color: #fff !important;
  }
  .ping-box.hv-theme-default {
      border-color: #fff !important;
  }
  .ping-box.hv-theme-default .ping-header {
      background-color: #1e692e !important;
  }
  .ping-box.hv-theme-default .search i.fa-search {
      color: #1e692e !important;
  }
  .hv-app-body.hv-theme-default .contact-us-form .ng-button-filled-primary {
      background-color: #1e692e !important;
  }
  .hv-app-body.hv-theme-default .theme-control .MuiSelect-select.MuiSelect-select {
      color: #e3bc17!important;
  }
}
