@charset "utf-8";
body.mcs-html {
  .hv-app-body.hv-theme-dark .hv-section:nth-of-type(odd) {
      background-color: #000 !important;
      border: 1px rgba(0, 0, 0, 0.5) solid !important;
      border-left-width: 0 !important;
      border-right-width: 0 !important;
  }
  .hv-app-body.hv-theme-dark .hv-section:nth-of-type(even) {
      background-color: #171717 !important;
  }
  .hv-app-body.hv-theme-dark .hv-jumbotron-banner {
      background-color: #000;
      border-bottom: 1px rgba(0, 0, 0, 0.5) solid;
      background-image: url('../../../assets/img/microsite/hero/dark-bg@2x.png') !important;
  }
  .hv-app-body.hv-theme-dark .header-copyright label {
      color: #000;
  }
  .hv-app-body.hv-theme-dark .header-copyright label a {
      color: #fff !important;
  }
  .hv-app-body.hv-theme-dark .corporate-name-wrapper .corporate-mis-vision,
  .hv-app-body.hv-theme-dark .corporate-name-wrapper .corporate-name,
  .hv-app-body.hv-theme-dark .corporate-primary .corporate-mis-vision,
  .hv-app-body.hv-theme-dark .corporate-primary .corporate-name {
      color: #fff !important;
  }
  .hv-app-body.hv-theme-dark .hv-section__inner-wrapper .section-primary-title {
      color: #fff !important;
  }
  .hv-app-body.hv-theme-dark .hv-section__inner-wrapper .section-secondary-title {
      color: #fff !important;
  }
  .hv-app-body.hv-theme-dark .hv-section__inner-wrapper .corporate-mis-vision,
  .hv-app-body.hv-theme-dark .hv-section__inner-wrapper .corporate-name {
      color: #fff !important;
  }
  .hv-app-body.hv-theme-dark .hv-section__inner-wrapper .corporate-presence li {
      color: #fff;
  }
  .hv-app-body.hv-theme-dark .hv-section__inner-wrapper .corporate-presence li:not(:last-of-type)::after {
      background-color: #fff;
  }
  .hv-app-body.hv-theme-dark .hv-section__inner-wrapper .address.icon-mobile::before {
      background-image: url(../../../assets/img/microsite/app-icons/icon-phone-white.svg) !important;
  }
  .hv-app-body.hv-theme-dark .hv-section__inner-wrapper .address.icon-email::before {
      background-image: url(../../../assets/img/microsite/app-icons/icon-email-white.svg) !important;
  }
  .hv-app-body.hv-theme-dark .hv-section__inner-wrapper .address.icon-location::before {
      background-image: url(../../../assets/img/microsite/app-icons/icon-location-pin-white.svg) !important;
  }
  .hv-app-body.hv-theme-dark .hv-section__inner-wrapper .address.icon-web::before {
      background-image: url(../../../assets/img/microsite/app-icons/icon-web-white.svg) !important;
  }
  .hv-app-body.hv-theme-dark .hv-section__inner-wrapper .address a,
  .hv-app-body.hv-theme-dark .hv-section__inner-wrapper .address label {
      color: #fff !important;
  }
  .hv-app-body.hv-theme-dark .hv-section__inner-wrapper .section-primary-description,
  .hv-app-body.hv-theme-dark .hv-section__inner-wrapper p.section-primary-description {
      color: #86868b !important;
  }
  .hv-app-body.hv-theme-dark .theme-home-text {
      color: #fff;
  }
  .hv-app-body.hv-theme-dark .menu-divider::before {
      color: #fff;
  }
  .hv-app-body.hv-theme-dark .pills-wrapper .pills-item {
      color: #86868b;
      background-color: transparent !important;
  }
  .hv-app-body.hv-theme-dark .section-carousel-wrapper .card-header {
      border-color: #333 !important;
  }
  .hv-app-body.hv-theme-dark .section-carousel-wrapper .card-header .card-primary-title,
  .hv-app-body.hv-theme-dark .section-carousel-wrapper .card-header h4.card-primary-title {
      color: #86868b !important;
  }
  .hv-app-body.hv-theme-dark .section-carousel-wrapper .card-header a.underline-me {
      color: #3170ce !important;
  }
  .hv-app-body.hv-theme-dark .section-carousel-wrapper .card-text {
      color: #86868b !important;
  }
  .hv-app-body.hv-theme-dark .section-carousel-wrapper .carousel-control-next,
  .hv-app-body.hv-theme-dark .section-carousel-wrapper .carousel-control-prev {
      color: #333 !important;
  }
  .hv-app-body.hv-theme-dark .section-carousel-wrapper .carousel-control-prev-icon {
      background-image: url('../../../assets/img/hero/theme3-bg.png') !important;
  }
  .hv-app-body.hv-theme-dark .section-carousel-wrapper .carousel-control-next-icon {
      background-image: url('../../../assets/img/hero/theme3-bg.png') !important;
  }
  .hv-app-body.hv-theme-dark .hv-form-check-input:checked ~ .form-check-label::before {
      background-color: #3170ce;
  }
  .hv-app-body.hv-theme-dark .hv-form-check-input:checked ~ .form-check-label:after {
      border-color: #3170ce;
  }
  .hv-app-body.hv-theme-dark .contact-us-form .form-check-label,
  .hv-app-body.hv-theme-dark .contact-us-form .hv-form-label {
      color: #fff;
  }
  .hv-app-body.hv-theme-dark .contact-us-form .btn.dropdown-toggle,
  .hv-app-body.hv-theme-dark .contact-us-form .hv-form-control {
      border-color: #86868b !important;
      background-color: #333 !important;
  }
  .hv-app-body.hv-theme-dark .contact-us-form .dropdown-toggle::after,
  .hv-app-body.hv-theme-dark .contact-us-form .filter-option-inner-inner,
  .hv-app-body.hv-theme-dark .contact-us-form .hv-form-control {
      color: rgba(255, 255, 255, 0.8) !important;
  }
  .hv-app-body.hv-theme-dark .vertical-divider-line {
      background-color: #86868b !important;
  }
  .hv-app-body.hv-theme-dark .hv-btn.hv-btn-primary {
      background-color: #3170ce !important;
  }
  .hv-app-body.hv-theme-dark .hv-btn.hv-btn-primary:not(:disabled):hover {
      background-color: #2c65b9 !important;
  }
  .hv-app-body.hv-theme-dark .hv-btn.hv-btn-primary:active,
  .hv-app-body.hv-theme-dark .hv-btn.hv-btn-primary:focus {
      background-color: #2c65b9 !important;
  }
  .hv-primary-navbar.hv-theme-dark .nav-link {
      color: #fff;
      font-weight: 400;
  }
  .hv-primary-navbar.hv-theme-dark .nav-link:hover {
      color: #3170ce !important;
  }
  .hv-primary-navbar.hv-theme-dark .nav-link.active {
      color: #3170ce !important;
      font-weight: 500;
  }
  .hv-theme-dark .theme-select-dd {
      background-color: transparent !important;
      border-color: #e3bc17 !important;
  }
  .hv-theme-dark .theme-select-dd .btn.dropdown-toggle {
      background-color: transparent !important;
      border-color: #e3bc17 !important;
  }
  .hv-theme-dark .theme-select-dd .btn.dropdown-toggle .filter-option-inner-inner {
      color: #e3bc17 !important;
  }
  .hv-theme-dark .theme-select-dd .btn.dropdown-toggle::after {
      color: #fff !important;
  }
  .ping-box.hv-theme-dark {
      border-color: #fff !important;
  }
  .ping-box.hv-theme-dark .ping-header {
      background-color: #3170ce !important;
  }
  .ping-box.hv-theme-dark .search i.fa-search {
      color: #3170ce !important;
  }

  .hv-app-body.hv-theme-dark .contact-us-form .hv-form-control .MuiInputBase-input,
  .hv-app-body.hv-theme-dark .contact-us-form .hv-form-control .MuiInputLabel-root {
      color: white;
  }

  .hv-app-body.hv-theme-dark .contact-us-form .ng-button-filled-primary {
      background-color: #3170ce !important;
  }

  .hv-app-body.hv-theme-dark .form-info-wrapper .message-wrapper label {
      color: white;
  }
  .hv-app-body.hv-theme-dark .form-info-wrapper .message-wrapper.error label {
      color: #e50000;
  }
  .hv-app-body.hv-theme-dark .theme-control .MuiSelect-select.MuiSelect-select {
      color: #e3bc17!important;
  }

  .hv-app-body.hv-theme-dark .ng-form-control-helper-label {
      color: white;
  }
  .hv-app-body.hv-theme-dark #microsite-area-code-label,
  .hv-app-body.hv-theme-dark #microsite-location-code-label {
      color: white;
  }
  .hv-app-body.hv-theme-dark .ng-form-control-helper-label.ng-form-control-helper-label-error {
      color: #f44336;
  }
}
