@charset "utf-8";
body.mcs-html {
  .hv-app-body.hv-theme-bright .hv-section:nth-of-type(odd) {
      background-color: #e4e8e7 !important;
      border: 1px #cbcecd solid !important;
      border-left-width: 0 !important;
      border-right-width: 0 !important;
  }
  .hv-app-body.hv-theme-bright .hv-section:nth-of-type(even) {
      background-color: #fff !important;
  }
  .hv-app-body.hv-theme-bright .hv-jumbotron-banner {
      background-color: #e4e8e7;
      border-bottom: 1px #cbcecd solid;
      background-image: url('../../../assets/img/microsite/hero/bright-bg@2x.png') !important;
  }
  .hv-app-body.hv-theme-bright .header-copyright label {
      color: #000;
  }
  .hv-app-body.hv-theme-bright .header-copyright label a {
      color: #fd9513 !important;
  }
  .hv-app-body.hv-theme-bright .corporate-name-wrapper .corporate-mis-vision,
  .hv-app-body.hv-theme-bright .corporate-name-wrapper .corporate-name,
  .hv-app-body.hv-theme-bright .corporate-primary .corporate-mis-vision,
  .hv-app-body.hv-theme-bright .corporate-primary .corporate-name {
      color: #000 !important;
  }
  .hv-app-body.hv-theme-bright .hv-section__inner-wrapper .section-primary-title {
      color: #000 !important;
  }
  .hv-app-body.hv-theme-bright .hv-section__inner-wrapper .section-secondary-title {
      color: #141414 !important;
  }
  .hv-app-body.hv-theme-bright .hv-section__inner-wrapper .corporate-mis-vision,
  .hv-app-body.hv-theme-bright .hv-section__inner-wrapper .corporate-name {
      color: #000 !important;
  }
  .hv-app-body.hv-theme-bright .hv-section__inner-wrapper .corporate-presence li {
      color: #000;
  }
  .hv-app-body.hv-theme-bright .hv-section__inner-wrapper .corporate-presence li:not(:last-of-type)::after {
      background-color: #333;
  }
  .hv-app-body.hv-theme-bright .hv-section__inner-wrapper .address.icon-mobile::before {
      background-image: url(../../../assets/img/microsite/app-icons/icon-phone-black.svg) !important;
  }
  .hv-app-body.hv-theme-bright .hv-section__inner-wrapper .address.icon-email::before {
      background-image: url(../../../assets/img/microsite/app-icons/icon-email-black.svg) !important;
  }
  .hv-app-body.hv-theme-bright .hv-section__inner-wrapper .address.icon-location::before {
      background-image: url(../../../assets/img/microsite/app-icons/icon-location-pin-black.svg) !important;
  }
  .hv-app-body.hv-theme-bright .hv-section__inner-wrapper .address.icon-web::before {
      background-image: url(../../../assets/img/microsite/app-icons/icon-web-black.svg) !important;
  }
  .hv-app-body.hv-theme-bright .hv-section__inner-wrapper .address a,
  .hv-app-body.hv-theme-bright .hv-section__inner-wrapper .address label {
      color: #000 !important;
  }
  .hv-app-body.hv-theme-bright .theme-home-text {
      color: #000;
  }
  .hv-app-body.hv-theme-bright .menu-divider::before {
      color: #000;
  }
  .hv-app-body.hv-theme-bright .pills-wrapper .pills-item {
      color: #141414;
  }
  .hv-app-body.hv-theme-bright .section-carousel-wrapper .card-header {
      border-color: #fd9513 !important;
  }
  .hv-app-body.hv-theme-bright .section-carousel-wrapper .card-header a.underline-me {
      color: #fd9513;
  }
  .hv-app-body.hv-theme-bright .section-carousel-wrapper .carousel-control-next,
  .hv-app-body.hv-theme-bright .section-carousel-wrapper .carousel-control-prev {
      color: #fd9513 !important;
  }
  /* .hv-app-body.hv-theme-bright .section-carousel-wrapper .carousel-control-prev-icon {
      background-image: url(../../../assets/img/microsite/app-icons/icon-carousel-previous-orange.svg) !important;
  }
  .hv-app-body.hv-theme-bright .section-carousel-wrapper .carousel-control-next-icon {
      background-image: url(../../../assets/img/microsite/app-icons/icon-carousel-next-orange.svg) !important;
  } */
  .hv-app-body.hv-theme-bright .hv-form-check-input:checked ~ .form-check-label::before {
      background-color: #fd9513;
  }
  .hv-app-body.hv-theme-bright .hv-form-check-input:checked ~ .form-check-label:after {
      border-color: #fd9513;
  }
  .hv-app-body.hv-theme-bright .hv-btn.hv-btn-primary {
      background-color: #fd9513 !important;
  }
  .hv-app-body.hv-theme-bright .hv-btn.hv-btn-primary:not(:disabled):hover {
      background-color: #f48902 !important;
  }
  .hv-app-body.hv-theme-bright .hv-btn.hv-btn-primary:active,
  .hv-app-body.hv-theme-bright .hv-btn.hv-btn-primary:focus {
      background-color: #f48902 !important;
  }
  .hv-primary-navbar.hv-theme-bright .nav-link {
      color: #000;
      font-weight: 400;
  }
  .hv-primary-navbar.hv-theme-bright .nav-link:hover {
      color: #fd9513 !important;
  }
  .hv-primary-navbar.hv-theme-bright .nav-link.active {
      color: #fd9513 !important;
      font-weight: 500;
  }
  .hv-theme-bright .theme-select-dd {
      background-color: rgba(255, 255, 255, 0) !important;
      border-color: #000 !important;
  }
  .hv-theme-bright .theme-select-dd .btn.dropdown-toggle {
      background-color: transparent !important;
      border-color: #000 !important;
  }
  .hv-theme-bright .theme-select-dd .btn.dropdown-toggle .filter-option-inner-inner {
      color: #000 !important;
  }
  .hv-theme-bright .theme-select-dd .btn.dropdown-toggle::after {
      color: #000 !important;
  }
  .hv-theme-bright .btn.icon-button-transparent {
      background-color: rgba(255, 255, 255, 0) !important;
      border-color: #000 !important;
  }
  .hv-theme-bright .btn.icon-button-transparent::after {
      color: #000 !important;
  }
  .ping-box.hv-theme-bright .ping-header {
      background-color: #fd9513 !important;
  }
  .ping-box.hv-theme-bright .search i.fa-search {
      color: #fd9513 !important;
  }

  .hv-app-body.hv-theme-bright .contact-us-form .ng-button-filled-primary {
      background-color: #fd9513 !important;
  }
}
